import { useState, useEffect } from "react"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import useWindowSize from "@util/hooks/useWindowSize"

interface Props {
  onVal: number
  coconVal: number | false
  maxVal: number
  maxInput: number
  disabled: boolean
  onMove: (values: number[]) => void
  onRelease: (values: number[]) => void
}

const ThresholdBar = ({
  onVal,
  coconVal,
  maxVal,
  maxInput,
  disabled,
  onMove,
  onRelease,
}: Props) => {
  const windowSize = useWindowSize()
  const [marks, setMarks] = useState({})

  useEffect(() => {
    if (windowSize.width) {
      let increment: number = Math.floor(maxInput / (windowSize.width > 640 ? 10 : 5) / 10) * 10

      let dict: { [key: number]: number } = {}
      for (let index = 0; index < maxInput; index += increment) {
        maxInput - index > increment / 2 && (dict[index] = index)
      }
      dict[maxInput] = maxInput
      setMarks(dict)
    }
  }, [maxInput, windowSize])

  return (
    <div className="flex flex-1 py-6">
      <Slider
        count={2}
        draggableTrack={true}
        allowCross={false}
        onChange={(values: number | number[]) => {
          if (typeof values === "number") return
          onMove(
            coconVal
              ? [values[0] > 400 ? values[0] : 400, values[1], values[2]]
              : [values[0] > 400 ? values[0] : 400, values[1]]
          )
        }}
        onAfterChange={(values: number | number[]) => {
          if (typeof values === "number") return
          onRelease(
            coconVal
              ? [values[0] > 400 ? values[0] : 400, values[1], values[2]]
              : [values[0] > 400 ? values[0] : 400, values[1]]
          )
        }}
        value={coconVal ? [onVal, coconVal, maxVal] : [onVal, maxVal]}
        marks={marks}
        range
        min={0}
        max={maxInput}
        trackStyle={[{ backgroundColor: "#C57C46" }, { backgroundColor: "#C57C46" }]}
        handleStyle={[
          { backgroundColor: "#C57C46", borderColor: "#C57C46", opacity: 1 },
          { backgroundColor: "#C57C46", borderColor: "#C57C46", opacity: 1 },
          { backgroundColor: "#C57C46", borderColor: "#C57C46", opacity: 1 },
        ]}
        dotStyle={{
          borderColor: "grey",
          height: 1,
          width: 1,
          bottom: 0.1,
        }}
        activeDotStyle={{
          borderColor: "white",
          height: 1,
          width: 1,
          bottom: 0.1,
        }}
        railStyle={{
          background: `linear-gradient( to right, grey 0%, grey ${
            (400 / maxInput) * 100
          }%, lightGrey ${(400 / maxInput) * 100}%, lightGrey 100%)`,
        }}
        disabled={disabled}
      />
    </div>
  )
}

export default ThresholdBar
