import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Alert, TextField } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"

import ResetByEmailInput from "@components/sections/ResetByEmailInput"
import ErrorMessage from "@ui/alerts/ErrorMessage"
import BasicModal from "@ui/modals/BasicModal"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCardFooter from "@ui/sections/CVCardFooter"
import logoMain from "@assets/images/logoMain.png"

const Login = () => {
  const { user, authError, signIn, sendResetPW } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    user?.emailVerified && navigate("/")
  }, [user, navigate])

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showingResetPasswordModal, setShowingResetPasswordModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<string>("")

  const handleResetPW = (email: string) => {
    setShowingResetPasswordModal(false)
    sendResetPW(email)
  }

  return (
    <>
      <img
        className="mx-auto w-9/12 md:w-5/12 lg:w-3/12 mb-[50px] max-w-xs lg:max-w-md"
        src={logoMain}
        alt="Covvi Logo"
      />
      <div className="w-11/12 md:w-7/12 lg:w-4/12 mx-auto  ">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            signIn(email, password)
          }}
        >
          <CVCardHeader>
            <CVCardTitle title={ls.getText("Sign In")} />
          </CVCardHeader>
          <CVCardBody>
            <div className="flex flex-col gap-y-8 px-[30px] py-[30px]">
              <TextField
                variant="outlined"
                label={ls.getText("Email Address")}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                error={!!authError}
                autoFocus
              >
                {email || null}
              </TextField>
              <TextField
                variant="outlined"
                label={ls.getText("Password")}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                error={!!authError}
              >
                {password || null}
              </TextField>
              <ErrorMessage message={authError} />
            </div>
          </CVCardBody>
          <CVCardFooter>
            <div className="flex flex-row flex-1 mx-[30px] justify-between">
              <Button
                variant="outlined"
                className=""
                onClick={() => {
                  setShowingResetPasswordModal(true)
                }}
              >
                {ls.getText("Reset Password")}
              </Button>
              <Button type="submit" variant="contained" className="" disableElevation>
                {ls.getText("Sign In")}
              </Button>
            </div>
          </CVCardFooter>
        </form>
      </div>
      <BasicModal
        open={showingResetPasswordModal}
        setClosed={() => setShowingResetPasswordModal(false)}
      >
        <ResetByEmailInput
          title="Reset Password"
          currentEmail={email}
          submitForm={handleResetPW}
          setShowingResetPasswordModal={setShowingResetPasswordModal}
          setSuccess={setSuccess}
        />
      </BasicModal>
      <BasicModal open={!!success} setClosed={() => setSuccess("")}>
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      </BasicModal>
    </>
  )
}

export default Login
