import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { getAllSupportTickets } from "@util/firebase/supportFunctions"
import { TicketContent } from "@typesFolder/supportTypes"
import { DefaultObject } from "@typesFolder/types"

import NewTicketModal from "@components/modals/supportTickets/NewTicketModal"
import Table from "@ui/table/Table"
import BasicModal from "@ui/modals/BasicModal"
import Spinner from "@ui/spinners/Spinner"

const SupportTickets = () => {
  const navigate = useNavigate()
  const { profile } = useAuth()
  const [tickets, setTickets] = useState<any>()
  const [newTicket, setNewTicket] = useState<TicketContent | undefined>()
  const [newTicketModalIsOpen, setNewTicketModalIsOpen] = useState<boolean>(false)
  const [success, setSuccess] = useState<string>("")
  const [ticketError, setTicketError] = useState<string>("")

  useEffect(() => {
    profile &&
      getAllSupportTickets(profile.role)
        .then((tickets) => {
          tickets.forEach((ticket: TicketContent & { full_name?: string }) => {
            ticket.full_name = ticket.firstName + " " + ticket.lastName
          })
          setTickets(tickets)
        })
        .catch((error) => {
          console.error(error)
        })
  }, [profile, newTicket])

  const openTicket = (data: DefaultObject) => {
    navigate(`/support-tickets/${data.id}`)
  }

  const newTableOptions = {
    fieldIds: ["full_name", "id", "ticketStatus", "serialNumber", "date"],
    fieldTitles: ["Name", "Ticket ID", "Ticket Status", "Serial Number", "Date Submitted"],
    button: {
      title: ls.getText("New Ticket"),
      onClick: () => setNewTicketModalIsOpen(true),
    },
    searchParams: ["full_name", "id", "serialNumber", "date"],
    onClick: openTicket,
  }

  return tickets ? (
    <>
      <Table data={tickets} tableOptions={newTableOptions} />
      {newTicketModalIsOpen && (
        <NewTicketModal
          setNewTicket={setNewTicket}
          modalOpen={newTicketModalIsOpen}
          setModalOpen={setNewTicketModalIsOpen}
          setSuccess={setSuccess}
          setTicketError={setTicketError}
        />
      )}
      {success && (
        <BasicModal open={!!success} setClosed={() => setSuccess("")}>
          <Alert severity="success" onClose={() => setSuccess("")}>
            {success}
          </Alert>
        </BasicModal>
      )}
      {ticketError && (
        <BasicModal open={!!ticketError} setClosed={() => setTicketError("")}>
          <Alert severity="error" onClose={() => setTicketError("")}>
            {ticketError}
          </Alert>
        </BasicModal>
      )}
    </>
  ) : (
    <Spinner />
  )
}

export default SupportTickets
