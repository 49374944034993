import { languageSelector as ls } from "@covvi/language-selector"
import { SystemInfoObj } from "@typesFolder/remoteAssistTypes"

import CVKeyValueInfoCard from "@ui/cards/CVKeyValueInfoCard"

interface Props {
  info: SystemInfoObj
}

const ConfigSystemInfoTab = ({ info }: Props) => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-[30px]">
      <CVKeyValueInfoCard title={ls.getText("config_version_name")} value={info.configVersion} />
      <CVKeyValueInfoCard title={ls.getText("Firmware Version")} value={info.firmwareVersion} />
      <CVKeyValueInfoCard title={ls.getText("Gateware Version")} value={info.gatewareVersion} />
      <CVKeyValueInfoCard title={ls.getText("Saved From Device")} value={info.userAppVersion} />
      <CVKeyValueInfoCard title={ls.getText("Set By")} value={info.setBy} />
      <CVKeyValueInfoCard title={ls.getText("Serial Number")} value={info.serialNumber} />
      {info.configImportedFrom && (
        <CVKeyValueInfoCard
          title={ls.getText("Config Imported From")}
          value={info.configImportedFrom}
        />
      )}
    </div>
  )
}

export default ConfigSystemInfoTab
