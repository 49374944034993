import { useState } from "react"
import { Checkbox, Alert } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { acceptDataConsent } from "@util/firebase/dataCollectionFunctions"

import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import ButtonWithLoading from "@ui/buttons/ButtonWithLoading"
import BasicModal from "@ui/modals/BasicModal"

interface Props {
  setShowing: Function
}

const DataCollection = ({ setShowing }: Props) => {
  const { profile } = useAuth()
  const [checked, setChecked] = useState<boolean>(false)
  const [accepting, setAccepting] = useState<boolean>(false)
  const [error, setError] = useState<string>("")

  const consent = () => {
    setAccepting(true)
    profile &&
      acceptDataConsent(profile?.uid)
        .then(() => {
          setAccepting(false)
          setShowing(false)
          window.location.reload()
        })
        .catch((e) => {
          setError(ls.getText("An Error Occured"))
          setAccepting(false)
        })
  }

  return (
    <>
      <CVCardHeader>
        <CVCardTitle title="Data Consent" />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[28px]">
          <h2>{ls.getText("accept_data_collection")}</h2>
          <br></br>
          <a
            className="underline text-blue-600"
            href="https://www.covvi.com/covvi-go/privacy-policy/"
            target="_blank"
          >
            {ls.getText("read_privacy_policy")}
          </a>
          <br></br>
          <h2></h2>
        </div>
      </CVCardBody>
      <CVCardFooter>
        <div className="flex flex-row flex-1 mx-[30px] justify-end">
          <Checkbox color="default" onChange={() => setChecked(!checked)} />
          <ButtonWithLoading
            title={ls.getText("Accept")}
            onClick={() => consent()}
            disabled={!checked}
            variant="contained"
            loading={accepting}
          />
        </div>
      </CVCardFooter>
      <BasicModal open={!!error} setClosed={() => setError("")}>
        <Alert severity="error">{error}</Alert>
      </BasicModal>
    </>
  )
}

export default DataCollection
