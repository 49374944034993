import { languageSelector as ls } from "@covvi/language-selector"

const SecondaryTabButton = ({
  index,
  title,
  selected,
  select,
}: {
  index: number
  title: string
  selected: boolean
  select: () => void
}) => {
  return (
    <div
      key={index}
      className={` ${selected ? "text-copper  border-copper border-b-4" : "text-gray-500"} ${
        !selected && "hover:text-black text-black/60 "
      }  py-[15px] ${index !== 0 ? "mx-[20px]" : "mr-[20px]"} cursor-pointer`}
      onClick={select}
    >
      <h2 className={"whitespace-nowrap"}>{ls.getText(title)}</h2>
    </div>
  )
}

export default SecondaryTabButton
