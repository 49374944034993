interface SortArg {
  [arg: string]: string | number
}

export const tableSorter = (sorting: { title: string; asc: boolean }, a: SortArg, b: SortArg) => {
  let stringA: string | number
  let stringB: string | number
  if (
    sorting.title === "date" ||
    (typeof a[sorting.title] === "number" && typeof b[sorting.title] === "number")
  ) {
    stringA = a[sorting.title]
    stringB = b[sorting.title]
  } else if (sorting.title === "Time") {
    stringA = parseInt(a[sorting.title] as string)
    stringB = parseInt(b[sorting.title] as string)
  } else {
    stringA = a[sorting.title].toString().toLowerCase()
    stringB = b[sorting.title].toString().toLowerCase()
  }

  if (stringA < stringB) {
    if (sorting.asc) {
      return -1
    } else {
      return 1
    }
  } else if (stringA > stringB) {
    if (sorting.asc) {
      return 1
    } else {
      return -1
    }
  } else {
    return 0
  }
}
