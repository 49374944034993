import { collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { firestore, functions } from "./firebase"
import {
  PossibleLangLong,
  PossibleLangShort,
  ProfileData,
  StoredTranslation,
  Translation,
} from "@typesFolder/types"
import { httpsCallable } from "firebase/functions"

export const getTranslations = () => {
  return new Promise<StoredTranslation[]>(async (resolve, reject) => {
    await getDocs(collection(firestore, "Translations"))
      .then((res) =>
        resolve(
          res.docs.map((tran) => {
            return { key: tran.id, ...tran.data() } as StoredTranslation
          })
        )
      )
      .catch((e) => reject(e))
  })
}

export const requestContext = (translation: StoredTranslation, email: string) => {
  return new Promise((resolve, reject) => {
    const functionsRequest = httpsCallable(functions, "getTranslationContext")
    functionsRequest({ translation, email }).then(resolve).catch(reject)
  })
}

export const updateTranslation = (
  profile: ProfileData,
  language: PossibleLangShort,
  key: string,
  translation: Translation
) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(firestore, "Translations", key), {
      [language]: {
        setBy: profile.uid,
        setOn: Date.now(),
        translation,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}
