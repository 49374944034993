import { useEffect, useState } from "react"

import { languageSelector as ls } from "@covvi/language-selector"
import { useCurrentHandContext, useCurrentSessionContext } from "@context/remoteAssistProviders"
import { Parser, Config as ParserConfig } from "@util/parser/Parser"
import { CurrentView } from "@typesFolder/remoteAssistTypes"

import DigitData from "./additionalTools/DigitData"
import ErrorLog from "./additionalTools/ErrorLog"
import ElectrodeData from "./additionalTools/ElectrodeData"
import HandStatusData from "./additionalTools/HandStatusData"
import CustomCommand from "./additionalTools/CustomCommand"

import { useAuth } from "@context/AuthContext"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import CVCardBody from "@ui/sections/CVCardBody"
import { Config } from "@typesFolder/types"

const AdditionalTools = ({ disableElectrodeHandles }: { disableElectrodeHandles: boolean }) => {
  const parser = new Parser()
  const { initialConfig, currentConfig, errorLogs, emitData } = useCurrentHandContext()
  const { client, currentView, setIsInCustomBLECommand } = useCurrentSessionContext()
  const { profile } = useAuth()

  const [parsedConfig, setParsedConfig] = useState<ParserConfig>()
  const [selected, setSelected] = useState<string>(
    currentView !== "Config" ? currentView : "Digit Data"
  )

  useEffect(() => {
    const config: Config | undefined = currentConfig ? currentConfig : initialConfig
    if (config) {
      let tempConfigVersion = config.configVersion?.toString() || ls.getText("Unknown")
      if (tempConfigVersion.includes(".")) {
        tempConfigVersion = tempConfigVersion.split(".").join("")
      }
      setParsedConfig(parser.parse(config.config, undefined, parseInt(tempConfigVersion)))
    }
  }, [initialConfig, currentConfig])

  useEffect(() => {
    if (parsedConfig) tabMap.filter((tab) => tab.title === selected)[0]?.start()
  }, [parsedConfig])

  useEffect(() => {
    currentView !== "Config" && setSelected(currentView)
  }, [currentView])

  const sendCommand = (command: string) => emitData({ type: "bleCommand", data: command })

  const tabMap: {
    title: CurrentView
    content: JSX.Element
    start: () => void
  }[] = [
    {
      title: "Digit Data",
      content: <DigitData sendCommand={sendCommand} handConfig={parsedConfig} />,
      start: () => emitData({ type: "current_view", data: "digits" }),
    },
    {
      title: "Electrode Data",
      content: (
        <ElectrodeData
          handConfig={parsedConfig!}
          disableElectrodeHandles={disableElectrodeHandles}
        />
      ),
      start: () => emitData({ type: "current_view", data: "electrode" }),
    },
    {
      title: "Hand Status Data",
      content: <HandStatusData />,
      start: () => emitData({ type: "current_view", data: "status" }),
    },
    {
      title: "Custom Bluetooth Command",
      content: <CustomCommand sendCommand={sendCommand} />,
      start: () => setIsInCustomBLECommand(true),
    },
    {
      title: "Errors",
      content: errorLogs ? (
        <ErrorLog
          errorDataArray={errorLogs.errorArray}
          latestTechError={errorLogs.latestTechTeamErrorTime}
          totalErrors={errorLogs.errorCount}
        />
      ) : (
        <></>
      ),
      start: () => emitData({ type: "request_errors", data: "Error Log" }),
    },
  ]

  return client ? (
    <div className="flex flex-col">
      <>
        <SecondaryTabArray
          buttonArray={tabMap.filter((tab) =>
            profile!.role === "Clinician" ? tab.title !== "Custom Bluetooth Command" && tab : tab
          )}
          selected={selected}
          setSelected={(title) => {
            setSelected(title)
            tabMap.filter((tab) => tab.title === title)[0].start()
          }}
          isWithinCard={false}
        />
        <div className="w-full mt-4">
          {selected === "Errors" || selected === "Digit Data" ? (
            tabMap.filter((tab) => tab.title === selected)[0].content
          ) : (
            <CVCardBody roundedTop={true} roundedBottom={true}>
              {parsedConfig && tabMap.filter((tab) => tab.title === selected)[0].content}
            </CVCardBody>
          )}
        </div>
      </>
    </div>
  ) : (
    <CVCardBody roundedTop={true} roundedBottom={true}>
      <h2 className="mx-auto py-20 text-xl">{ls.getText("No Client")}</h2>
    </CVCardBody>
  )
}
export default AdditionalTools
