import { useEffect, useState } from "react"
import validator from "validator"
import { TextField, Alert } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"

import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"

const ResetByEmailInput = ({
  error,
  currentEmail,
  submitForm,
  setShowingResetPasswordModal,
  setSuccess,
  alert,
  title,
}: {
  error?: string
  currentEmail?: string
  submitForm: (email: string) => void
  setShowingResetPasswordModal?: Function
  setSuccess?: Function
  alert?: string
  title: string
}) => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    currentEmail && setEmail(currentEmail)
  }, [currentEmail])

  useEffect(() => {
    error && setEmailError(error)
  }, [error])

  const handleSubmit = () => {
    setLoading(true)
    if (validator.isEmail(email)) {
      submitForm(email.toLowerCase())
      setSuccess && setSuccess(ls.getAlert("password_reset_success").title)
    } else {
      setEmailError(ls.getText("Please enter a valid email address"))
    }
    setLoading(false)
  }

  return (
    <>
      <div className="min-w-[310px]">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <CVCardHeader>
            <CVCardTitle title={ls.getText("Password Reset")} />
            {setShowingResetPasswordModal && (
              <CVCloseModal setShowingModal={setShowingResetPasswordModal} />
            )}
          </CVCardHeader>
          <CVCardBody>
            <div className="p-[30px] space-y-[20px] min-h-[180px]">
              {alert && <Alert severity="error">{alert}</Alert>}
              <TextField
                className="w-full"
                error={!!emailError}
                helperText={!!emailError ? ls.getText("Please enter a valid email address") : ""}
                variant="outlined"
                label={ls.getText("Email Address")}
                value={email}
                disabled={loading}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
              />
            </div>
          </CVCardBody>
          <CVCardFooter>
            <ButtonRow
              buttonArray={[
                {
                  title: ls.getText("Send Reset Request"),
                  variant: "contained",
                  loading: loading,
                  onClick: handleSubmit,
                },
              ]}
            />
          </CVCardFooter>
        </form>
      </div>
    </>
  )
}

export default ResetByEmailInput
