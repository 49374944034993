import { useEffect, useRef, useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import SecondaryTabButton from "@ui/buttons/SecondaryTabButton"
import CVCardBody from "./CVCardBody"
import useWindowSize from "@util/hooks/useWindowSize"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"

export const SecondaryTabArray = ({
  buttonArray,
  selected,
  setSelected,
  isWithinCard,
}: {
  buttonArray: { title: string }[]
  selected: string
  setSelected: (title: string) => void
  isWithinCard: boolean
}) => {
  const [isScrollable, setIsScrollable] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { width } = useWindowSize()
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)
  const [showRightArrow, setShowRightArrow] = useState<boolean>(isScrollable ? true : false)

  useEffect(() => {
    checkShouldRenderArrows()
  }, [buttonArray])

  const checkShouldRenderArrows = () => {
    setShowLeftArrow(containerRef.current!.scrollLeft !== 0 ? true : false)
    setShowRightArrow(
      containerRef.current!.scrollLeft >=
        containerRef.current!.scrollWidth - containerRef.current!.offsetWidth - 1
        ? false
        : true
    )
  }

  useEffect(() => {
    if (containerRef.current?.scrollWidth && containerRef.current?.offsetWidth) {
      if (containerRef.current?.scrollWidth > containerRef.current?.offsetWidth) {
        setIsScrollable(true)
      } else {
        setIsScrollable(false)
      }
    }
  }, [width])

  let content = (
    <div className={`relative w-full`}>
      <>
        <div
          onClick={() => (containerRef.current!.scrollLeft -= 200)}
          className="border-b-2 border-zinc-400 absolute h-[60px] w-[40px] left-[-40px] flex items-center justify-center"
        >
          {showLeftArrow && (
            <div className="cursor-pointer">
              <ArrowLeft fill={"#888888"} />
            </div>
          )}
        </div>
        <div
          className={`border-b-2 border-zinc-400 absolute h-[60px] w-[40px] right-[-40px] items-center justify-center flex`}
          onClick={() => (containerRef.current!.scrollLeft += 200)}
        >
          {showRightArrow && (
            <div className="cursor-pointer">
              <ArrowRight fill={"#888888"} />
            </div>
          )}
        </div>
      </>
      <div
        ref={containerRef}
        className="flex flex-row border-zinc-400 border-b-2 overflow-x-auto md:w-full h-[60px] scroll-smooth no-scrollbar left-[40px]"
        onScroll={checkShouldRenderArrows}
      >
        {buttonArray.map((tab, index) => {
          return (
            <SecondaryTabButton
              key={index}
              index={index}
              title={ls.getText(tab.title).toUpperCase().replaceAll("_", " ")}
              selected={selected === tab.title}
              select={() => setSelected(tab.title)}
            />
          )
        })}
      </div>
    </div>
  )

  if (!isWithinCard && width && width < 768) {
    return (
      <CVCardBody roundedTop={true}>
        <div className="px-[32px] overflow-hidden">{content}</div>
      </CVCardBody>
    )
  } else {
    return <>{content}</>
  }
}
