import _ from "lodash"

import {
  toHex,
  toInt,
  hexToVersion,
  toVersionInt,
  getConfigParamsSchema,
  getOptionGroupsSchema,
} from "@covvi/common-functions"
import { languageSelector } from "@covvi/language-selector"
import {
  ConfigVersion,
  ConfigSchema,
  OptionGroupsSchema,
  Param,
} from "@covvi/common-functions/lib/types"

class Parser {
  parse(config_data: string, hand_data: string | undefined, configVersion: number) {
    let _config_version: number
    let _hand_data!: HandData

    if (!hand_data) {
      _config_version = configVersion
    } else {
      _hand_data = new HandData(hand_data)
      _config_version = _hand_data.config_version
    }

    var config_schema: ConfigSchema = getConfigParamsSchema(_config_version as ConfigVersion)
    var option_groups_schema: OptionGroupsSchema = getOptionGroupsSchema(
      _config_version as ConfigVersion
    )

    let start,
      end = 0
    let data_slice: string = ""
    let config_result: any = {}
    let hexValues: string = config_data

    var mode_value: string = ""

    _.forEach(config_schema.config_params, function (value) {
      start = value.index * 2
      end = start + value.length * 2
      data_slice = config_data.slice(start, end)

      if (value.param_name === "mode") {
        mode_value = data_slice
      }

      if (value.param_type === "POSITIVE_INTEGER") {
        const configParam = new ConfigParamInteger(value, data_slice)
        config_result[configParam.param_name] = configParam || "N/A"
      } else if (value.param_type === "OPTION") {
        const configParam = new ConfigParamOption(
          value,
          data_slice,
          option_groups_schema,
          mode_value
        )
        config_result[value.param_name] = configParam
      } else if (value.param_type === "BOOL") {
        const configParam = new ConfigParamBool(value, data_slice)
        config_result[value.param_name] = configParam
      } else if (value.param_type === "VERSION") {
        const configParam = new ConfigParamVersion(value, data_slice)
        config_result[value.param_name] = configParam
      } else if (value.param_type === "CONFIG_VERSION") {
        const configParam = new ConfigParamConfigVersion(value, data_slice)
        config_result[value.param_name] = configParam
      } else if (value.param_type === "INPUT_PERCENTAGE") {
        const max_input = value.param_name.startsWith("elec_a")
          ? toInt(config_result.elec_a_maximum_input.value)
          : toInt(config_result.elec_b_maximum_input.value)
        const configParam = new ConfigParamInputPercentage(value, data_slice, max_input)
        config_result[value.param_name] = configParam
      } else {
      }
    })
    if (hand_data) {
      return new Config(config_result, hexValues, _hand_data)
    } else {
      return new Config(config_result, hexValues)
    }
  }

  unparse(config: any) {
    let start = 0
    let length = 0
    let paddedData = config.hexValues
    let replace_range = this.replaceRange

    _.forEach(
      config.params,
      (
        param:
          | ConfigParamOption
          | ConfigParamInteger
          | ConfigParamInputPercentage
          | ConfigParamConfigVersion
      ) => {
        start = param.index
        length = param.length
        paddedData = replace_range(paddedData, start * 2, start * 2 + length * 2, param.value)
      }
    )
    return paddedData
  }

  toHex(decimalNumber: number, minByteSize = 1) {
    const hex = Number(decimalNumber).toString(16).toUpperCase()
    return _.padStart(hex, minByteSize * 2, "0")
  }

  replaceRange(str: string, start: number, end: number, substitute: string) {
    return str.substring(0, start) + substitute + str.substring(end)
  }

  // build_config_result(config) {
  //   var config_result = {
  //     version: config_schema.version,
  //     config: config,
  //   };
  //   return config_result;
  // }
}

class HandData {
  firmware_version: string
  firmware_version_int: number
  hardware_version: number
  fpga_version: string
  fpga_version_int: number
  config_version: number
  hand_size: number

  constructor(hex_data: string) {
    this.firmware_version = hexToVersion(hex_data.substring(0, 6))
    this.firmware_version_int = toVersionInt(hex_data.substring(0, 6))
    this.hardware_version = toInt(hex_data.substring(6, 8))
    this.fpga_version = hexToVersion(hex_data.substring(8, 14))
    this.fpga_version_int = toVersionInt(hex_data.substring(8, 14))
    this.config_version = toInt(hex_data.substring(14, 18))
    this.hand_size = toInt(hex_data.substring(18))
  }
}

export class ConfigParam {
  param_name: string
  display_name: string
  index: number
  length: number
  param_type: string
  config_param: Param
  value!: string

  constructor(config_param: Param) {
    this.param_name = config_param.param_name
    this.display_name = languageSelector.getText(config_param.display_name)
    this.index = config_param.index
    this.length = config_param.length
    this.param_type = config_param.param_type
    this.config_param = config_param
  }
}

class ConfigParamInteger extends ConfigParam {
  value: string
  unit: string
  display_value!: string
  minimum?: string
  maximum?: string
  step?: string
  message?: string
  constructor(config_param: Param, data: string) {
    super(config_param)

    this.value = data
    this.unit = config_param.unit ? config_param.unit : ""
    this.minimum = config_param.minimum
    this.maximum = config_param.maximum
    this.step = config_param.step
    this.message = config_param.message
    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    // If the unit is 's' we want to divide by 1000 so we have the value in seconds
    if (this.unit === "s") {
      this.display_value = (toInt(this.value) / 1000.0).toString() + this.unit
    } else {
      this.display_value = toInt(this.value).toString() + " " + this.unit
    }

    if (this.display_value === "0 min.") {
      this.display_value = languageSelector.getText("off_name")
    }
    if (this.display_value === "0s") {
      this.display_value = languageSelector.getText("off_name")
    }
  }

  /* clone() {
    return new ConfigParamInteger(this.config_param, this.value)
  } */
}

class ConfigParamOption extends ConfigParam {
  option_group: any
  mode_value: any
  schema: any
  display_value!: string

  constructor(config_param: Param, data: string, schema: any, mode_value: string) {
    super(config_param)

    this.option_group = config_param.option_group
      ? config_param.option_group
      : config_param.param_name

    this.mode_value = mode_value
    this.value = data
    this.schema = schema
    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    var suffix = ""
    if (this.option_group === "electrode_select" && this.mode_value === "02") {
      suffix = "02"
    }
    const config_group_data_key = _.find(this.schema.option_groups[this.option_group + suffix], [
      "value",
      this.value,
    ])
    if (config_group_data_key !== undefined) {
      this.display_value = languageSelector.getText(config_group_data_key.display_name)
    } else {
      this.display_value = "N/A"
    }
  }

  /* clone() {
    return new ConfigParamOption(
      this.config_param,
      this.value,
      this.schema,
      this.mode_value
    )
  } */
}

class ConfigParamBool extends ConfigParam {
  display_value!: string

  constructor(config_param: Param, data: string) {
    super(config_param)

    this.value = data
    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    this.display_value =
      toInt(this.value) >= 1
        ? languageSelector.getText("on_name")
        : languageSelector.getText("off_name")
  }

  /* clone() {
    return new ConfigParamBool(this.config_param, this.value)
  } */
}

class ConfigParamVersion extends ConfigParam {
  display_value!: string

  constructor(config_param: Param, data: string) {
    super(config_param)
    this.value = data
    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    this.display_value = hexToVersion(this.value)
  }

  /* clone() {
    return new ConfigParamVersion(this.config_param, this.value)
  } */
}

class ConfigParamConfigVersion extends ConfigParam {
  display_value!: string

  constructor(config_param: Param, data: string) {
    super(config_param)

    this.value = data
    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    //this.display_value = (parseFloat(toInt(this.value)) * 0.01).toString()
    this.display_value = (toInt(this.value) * 0.01).toString()
  }

  /* clone() {
    return new ConfigParamConfigVersion(this.config_param, this.value)
  } */
}

class ConfigParamInputPercentage extends ConfigParam {
  unit: string
  max_input: number
  isPercentage: boolean
  display_value!: string

  constructor(config_param: Param, data: string, max_input: number) {
    super(config_param)

    this.value = data
    this.unit = config_param.unit ? config_param.unit : ""
    this.max_input = max_input
    this.isPercentage = false

    this.setDisplayValue()
  }

  updateValue(data: string) {
    this.value = data
    this.setDisplayValue()
  }

  setDisplayValue() {
    if (this.isPercentage) {
      const percentage = toInt(((toInt(this.value) / this.max_input) * 1000.0).toFixed(0))
      this.display_value = (percentage / 10.0).toString() + this.unit
    } else {
      this.display_value = toInt(this.value).toString() + this.unit
    }
  }

  /* clone() {
    return new ConfigParamInputPercentage(
      this.config_param,
      this.value,
      this.max_input
      )
    } */
}

interface Params {
  [key: string]:
    | ConfigParamOption
    | ConfigParamInteger
    | ConfigParamInputPercentage
    | ConfigParamConfigVersion
}

export class Config {
  params: Params
  hexValues: string
  numOfInputs: number
  handData?: HandData
  changes: any

  constructor(params: Params, hexValues: string, handData?: HandData) {
    this.params = params
    this.hexValues = hexValues
    this.numOfInputs = this.getValue("mode") === "02" ? 2 : 1
    this.handData = handData

    this.changes = {}
  }

  /* clone() {
    let copyParams: any = {}
    for (var key in this.params) {
      copyParams[key] = this.params[key].clone()
    }
    return new Config(copyParams, this.hexValues, this.handData)
  } */

  getParam(named: string) {
    return this.params[named]
  }

  getValue(named: string) {
    if (named === undefined) {
      return "0" + (this.numOfInputs - 1)
    }
    if (this.params[named] === undefined) {
      console.error("THERE WAS AN ERROR WITH: this.params[named]")
    }
    return this.params[named].value
  }

  removeGrip(named: string) {
    if (named === "table_a_grip_1") {
      let onlyGrip = true
      for (let i = 2; i <= 6; i++) {
        if (this.getParam(`table_a_grip_${i}`).value !== "00") {
          onlyGrip = false
        }
      }
      if (onlyGrip) return
    }

    let table_index = parseInt(named.substring(13, 14))
    var found00 = false
    for (var i = table_index; i <= 5; i++) {
      const value = this.params[named.substring(0, 13) + (i + 1)].value
      if (!found00 && value === "00") {
        found00 = true
        this.updateParamValue(named.substring(0, 13) + "count", toHex(i - 1))
      }
      this.updateParamValue(
        named.substring(0, 13) + i,
        this.params[named.substring(0, 13) + (i + 1)].value
      )
    }

    if (!found00) {
      this.updateParamValue(named.substring(0, 13) + "count", toHex(5))
    }

    this.updateParamValue(named.substring(0, 13) + "6", "00")
  }

  addGrip(table: string, value: string) {
    const tableName = "table_" + [table] + "_grip_"
    for (var i = 1; i <= 6; i++) {
      if (this.params[tableName + i].value === "00") {
        this.updateParamValue(tableName + "count", toHex(i))
        this.updateParamValue(tableName + i, value)
        return
      }
    }
  }

  updateHexValues(
    param:
      | ConfigParamOption
      | ConfigParamInteger
      | ConfigParamInputPercentage
      | ConfigParamConfigVersion
  ) {
    this.hexValues =
      this.hexValues.substring(0, param.index * 2) +
      param.value +
      this.hexValues.substring((param.index + param.length) * 2)
  }

  updateParamValue(named: string, newValue: string) {
    var value = newValue
    var name = named
    if (name === languageSelector.getText("num_inputs_title")) {
      value = "02"
      name = "mode"
      this.numOfInputs = 2
      if (newValue === "00") {
        value = "00"
        this.numOfInputs = 1
      }
    }
    const original_display_value = this.params[name].display_value
    const original_value = this.params[name].value
    if (original_value === value) {
      return
    }

    this.params[name].updateValue(value)
    this.updateHexValues(this.params[name])

    if (this.changes[name] === undefined) {
      this.changes[name] = {
        original: original_display_value,
        new: this.params[name].display_value,
        display_name: this.params[name].display_name,
      }
    } else {
      if (this.changes[name].original === this.params[name].display_value) {
        delete this.changes[name]
      } else {
        this.changes[name].new = this.params[name].display_value
      }
    }

    // Check if the value being changed is single_co_contraction
    // If it is then we want to check that the cocontraction values are also changed
    if (named === "single_co_contraction" && value !== "00") {
      this.updateCoconThresholds()
    }
  }

  updateCoconThresholds() {
    const inputAOn = toInt(this.getParam("elec_a_on_threshold").value)
    const inputACocon = toInt(this.getParam("elec_a_cocon_threshold").value)
    const inputAOnCoconBuffer = toInt(this.getParam("elec_a_on_cocon_buffer").value)

    if (inputACocon < inputAOn + inputAOnCoconBuffer) {
      this.updateParamValue("elec_a_cocon_threshold", toHex(inputAOn + inputAOnCoconBuffer, 2))
    }

    const inputBOn = toInt(this.getParam("elec_b_on_threshold").value)
    const inputBCocon = toInt(this.getParam("elec_b_cocon_threshold").value)
    const inputBOnCoconBuffer = toInt(this.getParam("elec_b_on_cocon_buffer").value)

    if (inputBCocon < inputBOn + inputBOnCoconBuffer) {
      this.updateParamValue("elec_b_cocon_threshold", toHex(inputBOn + inputBOnCoconBuffer, 2))
    }
  }

  /* updateThresholds(
    inputStartName: string,
    gainValue: any,
    originalGainValue: any
  ) {
    ;[
      inputStartName + 'on_threshold',
      inputStartName + 'cocon_threshold',
      inputStartName + 'max_threshold',
    ].forEach((i: any) => {
      // get the current value
      const ratio = toInt(this.getValue(i)) / toInt(originalGainValue)

      const intGain = toInt(gainValue)
      const intTimesRatio = intGain * ratio
      const newValue = toHex(intTimesRatio, 2)
      //const newValue = toHex(parseInt(toInt(gainValue) * ratio), 2)

      this.params[i].updateValue(newValue)
      this.updateHexValues(this.params[i])
    })
  } */

  // This uses the unparser to get the full string of hex values for the config.
  /* getUpdatedConfigHex() {
    const parser = new Parser()
    var newHex = parser.unparse(this)

    return newHex
  } */

  /*  range(size: number, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
  }  */
}

class HandStatus {
  data!: string
  holdOpenTrigger!: boolean
  openOpenTrigger!: boolean
  cocontractionTrigger!: boolean
  backButtonTrigger!: boolean

  constructor(data: string) {
    this.data = data

    this.getBitmask(data.substring(0, 6))
  }

  getBitmask(data: string) {
    let bits = parseInt(data, 16).toString(2)
    bits = "0".repeat(24 - bits.length) + bits

    this.holdOpenTrigger = bits.charAt(20) === "1"
    this.openOpenTrigger = bits.charAt(15) === "1"
    this.cocontractionTrigger = bits.charAt(14) === "1"
    this.backButtonTrigger = bits.charAt(13) === "1"
  }
}

export { Parser, HandStatus }

// 440100 / open open
// 440008 / hold open
