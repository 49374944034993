import React from "react"

const CVCardFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-white w-full min-h-[76px] rounded-b-xl flex flex-row mx-auto items-center overflow-hidden border-t border-[#E9E9E9] shadow-md">
      {children}
    </div>
  )
}

export default CVCardFooter
