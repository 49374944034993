import { languageSelector as ls } from "@covvi/language-selector"

import { SxProps } from "@mui/material"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"
import ButtonWithLoading from "@ui/buttons/ButtonWithLoading"

const LabelButtonInfo = ({
  labelText,
  helperText,
  buttonText,
  disabled,
  onClick,
  isLoading,
  styleOverrides = {},
  isDisabledBecauseSuccess = false,
}: {
  labelText: string
  helperText: string
  buttonText: string
  isDisabledBecauseSuccess?: boolean
  disabled: boolean
  onClick: Function
  isLoading: boolean
  styleOverrides?: SxProps
}) => {
  return (
    <div className="flex flex-1 flex-row flex-grow items-center justify-between text-left w-full my-1">
      <p>{labelText}</p>
      <div className="flex flex-row items-center justify-end">
        <div className="mr-2">
          <HelpTextTooltip text={helperText} />
        </div>
        <ButtonWithLoading
          title={isDisabledBecauseSuccess ? "✔" : buttonText}
          onClick={() => onClick()}
          disabled={disabled || isDisabledBecauseSuccess}
          loading={isLoading}
          styleSXOverrides={{
            "&.MuiButton-root": {
              minWidth: 70,
            },
            ...(isDisabledBecauseSuccess && {
              "&.Mui-disabled": {
                background: "#2E7D32",
                color: "#FFFFFF",
              },
            }),
            ...styleOverrides,
          }}
        />
      </div>
    </div>
  )
}

export default LabelButtonInfo
