import { initializeApp } from "firebase/app"
import { connectFirestoreEmulator, initializeFirestore } from "firebase/firestore"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getAuth, connectAuthEmulator } from "firebase/auth"

const production = {
  apiKey: "AIzaSyBxLZMwE6CwuscuPpF3_vySoIYYypCptdg",
  authDomain: "covvi-go-app.firebaseapp.com",
  databaseURL: "https://covvi-go-app.firebaseio.com",
  projectId: "covvi-go-app",
  storageBucket: "covvi-go-app.appspot.com",
  messagingSenderId: "212116284044",
  appId: "1:212116284044:web:e96099ba028034d11cd1a9",
}
const staging = {
  apiKey: "AIzaSyABiMsc1E6pNLvbloLXDizu4gFnimlNGpM",
  authDomain: "covvi-staging.firebaseapp.com",
  databaseURL: "https://covvi-staging.firebaseio.com",
  projectId: "covvi-staging",
  storageBucket: "covvi-staging.appspot.com",
  messagingSenderId: "765031591493",
  appId: "1:765031591493:web:c14faf8a3b8f3d06e5bd2c",
}

const app = initializeApp(process.env.REACT_APP_ENV === "staging" ? staging : production)
export const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
})
export const auth = getAuth(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(firestore, "localhost", 8082)
  connectStorageEmulator(storage, "localhost", 9199)
  connectFunctionsEmulator(functions, "localhost", 5001)
  connectAuthEmulator(auth, "http://localhost:9099")
}
