import { useEffect, useState } from "react"

import { ErrorCodeProcessed } from "@typesFolder/remoteAssistTypes"
import Spinner from "@ui/spinners/Spinner"
import Table from "@ui/table/Table"
import { ErrorCodeData, NewTableOptions } from "@typesFolder/types"
import { LinearProgress } from "@mui/material"
import { useAuth } from "@context/AuthContext"
import { AlertSection } from "@ui/alerts/AlertSection"

const ErrorLog = ({
  errorDataArray,
  latestTechError,
  totalErrors,
}: {
  errorDataArray: ErrorCodeData[]
  latestTechError: number
  totalErrors?: number
}) => {
  const { profile } = useAuth()
  const [processedArray, setProcessedArray] = useState<ErrorCodeProcessed[]>([])

  const [faults, setFaults] = useState<{
    solvable: { type: string; code: string }[]
    warnings: { type: string; code: string }[]
    serious: { type: string; code: string }[]
  }>({
    solvable: [],
    warnings: [],
    serious: [],
  })

  useEffect(() => {
    let latestError = 1
    errorDataArray &&
      setProcessedArray(
        errorDataArray
          .map((error) => {
            error.absTime > latestError &&
              !(error.type === 2 && error.code === 2) &&
              (latestError = error.absTime)
            return {
              ...getTypeAndCode(error!.type, error!.code),
              logNumber: error!.logNumber,
              absTime: error!.absTime,
            }
          })
          .sort((a, b) => b.absTime - a.absTime)
      )
    let initialisations: number[] = []
    let currentTrips: { [digit: number]: number[] } = {}
    let thermalTrips: { [digit: number]: number[] } = {}
    let solvable: { type: string; code: string }[] = []
    let warnings: { type: string; code: string }[] = []
    let serious: { type: string; code: string }[] = []

    const hasMultipleOccurences = (arr: number[]) => {
      let result: number[][] = []
      let groupArray: number[]
      arr.sort((a, b) => a - b)
      arr.forEach((time, i) => {
        if (arr[i - 1] !== time) {
          groupArray = []
          result.push(groupArray)
        }
        groupArray.push(time)
      })
      return !!result.filter((group) => group.length >= 3)[0]
    }

    errorDataArray.forEach((error) => {
      if (latestError - error.absTime < 86400 && error.absTime > latestTechError) {
        error.type === 2 &&
          error.code === 1 &&
          initialisations.push(Math.round(error.absTime / 43200))
        if ([3, 4, 5, 6, 7, 8].includes(error.type)) {
          if ([1, 4].includes(error.code)) {
            !serious.filter(
              (seriousError) =>
                JSON.stringify(seriousError) ===
                JSON.stringify(getTypeAndCode(error.type, error.code))
            )[0] &&
              error.absTime > 7000 &&
              serious.push(getTypeAndCode(error.type, error.code))
          }
          if (error.code === 3) {
            !solvable.filter(
              (solvableError) =>
                JSON.stringify(solvableError) ===
                JSON.stringify(getTypeAndCode(error.type, error.code))
            )[0] &&
              error.absTime > 7000 &&
              solvable.push(getTypeAndCode(error.type, error.code))
          }
          if (error.code === 2 && error.absTime > 7000) {
            thermalTrips[error.type]
              ? thermalTrips[error.type].push(Math.round(error.absTime / 43200))
              : (thermalTrips[error.type] = [Math.round(error.absTime / 43200)])
          }
          if (error.code === 5 && error.absTime > 7000) {
            currentTrips[error.type]
              ? currentTrips[error.type].push(Math.round(error.absTime / 43200))
              : (currentTrips[error.type] = [Math.round(error.absTime / 43200)])
          }
        }
      }
    })

    hasMultipleOccurences(initialisations) && warnings.push(getTypeAndCode(2, 1))
    Object.entries(thermalTrips).forEach(([key, value], i) => {
      hasMultipleOccurences(value) && warnings.push(getTypeAndCode(parseInt(key), 2))
    })
    Object.entries(currentTrips).forEach(([key, value], i) => {
      hasMultipleOccurences(value) && warnings.push(getTypeAndCode(parseInt(key), 5))
    })
    setFaults({ solvable, warnings, serious })
  }, [errorDataArray])

  const getTypeAndCode = (type: number, code: number) => {
    const fingerFaults = ({ code, type }: { code: number; type: number }) => {
      switch (code) {
        case 1:
          return type === 8 ? "Speed Sensor Fault" : "Hall Sensor Fault"
        case 2:
          return "Thermal Trip"
        case 3:
          return "Invalid Limits"
        case 4:
          return "Wiper Fault"
        case 5:
          return "Current Trip"
        default:
          return "Unknown"
      }
    }

    switch (type) {
      case 1:
        return { type: "System Error", code: code === 1 ? "Incompatible Gateware" : "Unknown" }
      case 2:
        let newCode = "Unknown"
        if (code === 1) {
          newCode = "Initialisation Fault"
        }
        if (code === 2) newCode = "User initiated reset"
        return { type: "Bluetooth", code: newCode }
      case 3:
        return { type: "Thumb", code: fingerFaults({ code, type }) }
      case 4:
        return { type: "Index Finger", code: fingerFaults({ code, type }) }
      case 5:
        return { type: "Middle Finger", code: fingerFaults({ code, type }) }
      case 6:
        return { type: "Ring Finger", code: fingerFaults({ code, type }) }
      case 7:
        return { type: "Little Finger", code: fingerFaults({ code, type }) }
      case 8:
        return { type: "Thumb Rotation", code: fingerFaults({ code, type }) }
      case 9:
        return { type: "Flash Erase", code: "Failed to Erase Flash" }
      case 10:
        return { type: "Flash Program", code: "Failed to Program Flash" }
      case 11:
        return { type: "Flash Validate", code: "Flash Validation Failed" }
      case 12:
        return { type: "Update Abort", code: "Update procedure Aborted" }
      default:
        return { type: "Unknown", code: "Unknown" }
    }
  }

  const tableOptions: NewTableOptions = {
    fieldIds: ["absTime", "logNumber", "type", "code"],
    fieldTitles: ["Time", "Log Number", "Type", "Code"],
    searchParams: ["type", "code", "logNumber"],
  }

  return processedArray.length > 0 ? (
    <div className="h-full">
      {totalErrors && errorDataArray.length / (totalErrors > 511 ? 511 : totalErrors) < 1 && (
        <LinearProgress
          variant={profile?.role === "Clinician" ? "indeterminate" : "determinate"}
          value={(errorDataArray.length / (totalErrors > 511 ? 511 : totalErrors)) * 100}
        />
      )}
      <div className="py-4 space-y-4">
        {faults.serious.map((error, i) => (
          <AlertSection
            key={i}
            severity="error"
            title={`${error.type} ${error.code} error`}
            content={"serious_error_call"}
          />
        ))}
        {faults.warnings.map((error, i) => (
          <AlertSection
            key={i}
            severity="warning"
            title={`${error.type} ${error.code} warning`}
            content={"warning_error_call"}
          />
        ))}
        {faults.solvable.map((error, i) => (
          <AlertSection
            key={i}
            severity="info"
            title={`${error.type} ${error.code}`}
            content={"info_error_call"}
          />
        ))}
      </div>
      {!Object.values(faults).filter((errorArray) => errorArray.length)[0] && (
        <AlertSection severity="success" title={"no_error_call_1"} content={"no_error_call_2"} />
      )}
      {["Admin", "Tech Team Member", "Customer Service Team Member"].includes(profile!.role) && (
        <Table data={processedArray} tableOptions={tableOptions} />
      )}
    </div>
  ) : (
    <Spinner />
  )
}
export default ErrorLog
