import { TextField } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"

import { useReset } from "@context/AuthContext"
import ErrorMessage from "@ui/alerts/ErrorMessage"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"
import Spinner from "@ui/spinners/Spinner"
import { PasswordCheck } from "@ui/alerts/PasswordCheck"

const ResetPassword = ({ showingCurrent }: { showingCurrent: boolean | undefined }) => {
  const { resetPW, resetParams, setResetParams, resetResult, signIn } = useReset()

  return !resetResult.loading ? (
    <div className="mx-4 min-w-[300px]">
      {!showingCurrent && (
        <CVCardHeader>
          <CVCardTitle
            title={ls.getText(
              resetResult.success && !showingCurrent
                ? "Password successfully set"
                : "Choose a new password"
            )}
          />
        </CVCardHeader>
      )}
      <CVCardBody>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            resetPW()
          }}
        >
          <div className="py-[30px] md:px-[20px]">
            {!resetResult.success || showingCurrent ? (
              <>
                <div className="bg-gray-100 p-4 rounded-xl mb-8">
                  <PasswordCheck type={"pass_8_50"} password={resetParams.newPassword} />
                  <PasswordCheck type={"pass_must_upper"} password={resetParams.newPassword} />
                  <PasswordCheck type={"pass_no_special"} password={resetParams.newPassword} />
                  <PasswordCheck type={"pass_no_number"} password={resetParams.newPassword} />
                </div>
                {showingCurrent && (
                  <div className="my-6">
                    <TextField
                      className="w-full"
                      error={!!resetResult.error}
                      variant="outlined"
                      label={ls.getText("Current Password")}
                      value={resetParams.currentPassword}
                      autoComplete="current-password"
                      disabled={false}
                      required={true}
                      onChange={(e) =>
                        setResetParams((resetParams) => {
                          return { ...resetParams, currentPassword: e.target.value }
                        })
                      }
                      type="password"
                    />
                  </div>
                )}
                <div className="my-6">
                  <TextField
                    className="w-full"
                    error={!!resetResult.error}
                    variant="outlined"
                    label={ls.getText("New Password")}
                    value={resetParams.newPassword}
                    disabled={false}
                    required={true}
                    onChange={(e) =>
                      setResetParams((resetParams) => {
                        return { ...resetParams, newPassword: e.target.value }
                      })
                    }
                    type="password"
                  />
                </div>
                <div className="my-6">
                  <TextField
                    className="w-full"
                    error={!!resetResult.error}
                    variant="outlined"
                    label={ls.getText("Confirm New Password")}
                    value={resetParams.confirmNewPassword}
                    disabled={false}
                    required={true}
                    onChange={(e) =>
                      setResetParams((resetParams) => {
                        return { ...resetParams, confirmNewPassword: e.target.value }
                      })
                    }
                    type="password"
                  />
                </div>
                {resetResult.error && <ErrorMessage message={resetResult.error} />}
              </>
            ) : (
              <div className={"text-bold"}>{ls.getText("Press Ok to continue")}</div>
            )}
          </div>
        </form>
      </CVCardBody>
      {!showingCurrent && (
        <CVCardFooter>
          <ButtonRow
            buttonArray={[
              {
                variant: "contained",
                onClick: () => {
                  resetResult.success && resetParams.email && !showingCurrent
                    ? signIn(resetParams.email, resetParams.newPassword)
                    : resetPW()
                },
                color: resetResult.success && !showingCurrent ? "success" : "primary",
                title:
                  resetResult.success && !showingCurrent ? ls.getText("Ok") : ls.getText("Reset"),
              },
            ]}
          />
        </CVCardFooter>
      )}
    </div>
  ) : (
    <Spinner />
  )
}

export default ResetPassword
