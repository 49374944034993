import { useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { deleteConfig } from "@util/firebase/handFunctions"

import BasicModal from "@ui/modals/BasicModal"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCloseModal from "@ui/modals/CVCloseModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { useAuth } from "@context/AuthContext"
import { useNavigate } from "react-router-dom"
import { Config } from "@typesFolder/types"

interface Props {
  showingModal: boolean
  setShowingModal: Function
  config: Config
  serialNumber: string
  setAlert: Function
}

const DeleteConfigConfirmation = ({
  showingModal,
  setShowingModal,
  config,
  serialNumber,
  setAlert,
}: Props) => {
  const [deleting, setDeleting] = useState(false)
  const { profile } = useAuth()
  const navigate = useNavigate()

  const handleDelete = () => {
    setDeleting(true)

    profile &&
      deleteConfig(config, serialNumber, profile)
        .then(() => {
          setAlert("success")
          setTimeout(() => {
            setDeleting(false)
            navigate(`/hands/${serialNumber}`)
          }, 500)
        })
        .catch((e) => {
          setAlert("error")
          setDeleting(false)
        })
  }

  return (
    <BasicModal open={showingModal} setClosed={() => setShowingModal(false)}>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Are You Sure?")} />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col flex-1 space-y-2">
          <p>{`${ls.getAlert("deleteConfirmation").title} : ${config.name}`}</p>
        </div>
      </CVCardBody>
      <CVCardFooter>
        <>
          <ButtonRow
            buttonArray={[
              {
                title: ls.getText("Delete Config"),
                color: "error",
                variant: "contained",
                loading: deleting,
                onClick: () => {
                  handleDelete()
                },
              },
              {
                variant: "outlined",
                onClick: () => setShowingModal(false),
                title: ls.getText("Cancel"),
              },
            ]}
          />
        </>
      </CVCardFooter>
    </BasicModal>
  )
}

export default DeleteConfigConfirmation
