import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

const CVSubtitle = ({ text, helperText }: { text: string; helperText?: string }) => {
  return (
    <div className="flex flex-row mb-2 w-full">
      <h2 className="text-semibold text-lg font-bold leading-5">{text}</h2>
      {helperText && <HelpTextTooltip text={helperText} />}
    </div>
  )
}

export default CVSubtitle
