import { useEffect, useState } from "react"
import { TextField, MenuItem } from "@mui/material"
import { languageSelector as ls } from "@covvi/language-selector"
import { UserGrips } from "@typesFolder/remoteAssistTypes"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

const SelectInput = ({
  title,
  error,
  value,
  setValue,
  canEdit = true,
  userGrips,
  helperText,
}: {
  title?: string
  error?: string
  value: any
  setValue: Function
  canEdit?: boolean
  userGrips?: UserGrips | undefined
  helperText?: string
}) => {
  const paramName = value.param_name
  const optionGroup = value.schema.option_groups[value.option_group]
  const [displayValue, setDisplayValue] = useState<string>()

  useEffect(() => setDisplayValue(value.value), [value])
  useEffect(() => {
    if (displayValue && displayValue !== value.value) setValue(paramName, displayValue)
  }, [displayValue])

  return displayValue ? (
    <div className="flex justify-between py-2 items-center">
      <TextField
        className="basis-full"
        error={displayValue === "N/A" || displayValue === undefined || error ? true : false}
        helperText={title}
        variant="outlined"
        label={value.display_name || value.config_param?.display_name || ""}
        value={displayValue}
        select
        onChange={(e) => setDisplayValue(e.target.value)}
        required
        disabled={!canEdit}
        size="small"
      >
        {Object.entries(optionGroup)
          .sort((a: any, b: any) => a[1].display_name.localeCompare(b[1].display_name))
          .map(([key, value]: [string, any], i: number) => {
            if (key.startsWith("user_grip_") && userGrips) {
              let userGripIndex = parseInt(key.slice(key.length - 1)) - 1
              if (userGrips[userGripIndex.toString() as keyof UserGrips] === "No Grip") return
            }

            return (
              <MenuItem key={i} value={value.value}>
                {userGrips && value.display_name.startsWith("user_grip")
                  ? ls.getText("user_defined_grips") +
                    " - " +
                    ls.getText(userGrips[(i - 15).toString() as keyof UserGrips])
                  : ls.getText(value.display_name)}
              </MenuItem>
            )
          })}
      </TextField>
      {helperText && <HelpTextTooltip text={helperText} />}
    </div>
  ) : (
    <></>
  )
}

export default SelectInput
