import React from "react"
import { TextField } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"

interface Props {
  search: string
  setSearch: Function
}

const CVSearchBar = ({ search, setSearch }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  return (
    <TextField
      size="small"
      onChange={handleChange}
      variant="outlined"
      sx={{
        input: {
          width: "100%",
          overflow: "hidden",
        },
      }}
      placeholder={"🔍 " + ls.getText("Search")}
    >
      {search}
    </TextField>
  )
}

export default CVSearchBar
