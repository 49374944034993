import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "@mui/material"

import { ProfileData, NewErrorObj } from "@typesFolder/types"
import { useAuth } from "@context/AuthContext"
import { getMyUsers } from "@util/firebase/userFunctions"

import Table from "@ui/table/Table"
import Spinner from "@ui/spinners/Spinner"
import NewUserModal from "@components/modals/users/NewUserModal"
import BasicModal from "@ui/modals/BasicModal"
import useWindowSize from "@util/hooks/useWindowSize"

const Users = () => {
  const { width = 1024 } = useWindowSize()
  const [showingNewUser, setShowingNewUser] = useState<boolean>(false)
  const [alert, setAlert] = useState<NewErrorObj>()

  const [primaryLoading, setPrimaryLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const { user, profile } = useAuth()
  const [allUsers, setAllUsers] = useState<ProfileData[]>()

  useEffect(() => {
    if (user && profile) {
      getMyUsers(user.uid, profile.role)
        .then((users) => {
          users.forEach((user: ProfileData & { full_name?: string }) => {
            user.full_name = `${user.first_name} ${user.last_name}`
          })
          setAllUsers([...users])
        })
        .catch(() => setAllUsers([]))
    }
  }, [profile, user])

  const openUser = (data: ProfileData) => {
    navigate(`/users/${data.uid}`, {
      state: {
        data,
        firstNavToUser: true,
      },
    })
  }

  const tableOptions = {
    fieldIds: ["full_name", "email_address", ...(width > 640 ? ["role"] : [])],
    fieldTitles: ["Name", "Email Address", ...(width > 640 ? ["Role"] : [])],
    button: { title: "New User", onClick: () => setShowingNewUser(true) },
    onClick: openUser,
    searchParams: ["first_name", "last_name", "email_address", ...(width > 640 ? ["role"] : [])],
  }

  return allUsers ? (
    <>
      <Table data={allUsers} tableOptions={tableOptions} />
      {showingNewUser && (
        <NewUserModal
          showingNewUser={showingNewUser}
          setShowingNewUser={setShowingNewUser}
          setAlert={setAlert}
          primaryLoading={primaryLoading}
          setPrimaryLoading={setPrimaryLoading}
        />
      )}
      {alert && (
        <BasicModal open={!!alert} setClosed={() => setAlert(undefined)}>
          <Alert severity={alert.severity} onClose={() => setAlert(undefined)}>
            {alert.text}
          </Alert>
        </BasicModal>
      )}
    </>
  ) : (
    <Spinner />
  )
}

export default Users
