import {
  confirmPasswordReset,
  EmailAuthProvider,
  getIdTokenResult,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
  verifyPasswordResetCode,
} from "firebase/auth"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { auth, firestore, functions } from "./firebase"
import { httpsCallable } from "firebase/functions"
import { ProfileData, AuthData } from "@typesFolder/types"

export const getIdToken = (user: User) => {
  return new Promise((resolve, reject) => {
    getIdTokenResult(user)
      .then(resolve)
      .catch(() => reject("Couldn't get an Auth token"))
  })
}

export const login = (email: string, password: string) => {
  return new Promise<boolean>((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        if (res.user.emailVerified) {
          resolve(true)
        } else {
          signOut(auth)
          reject("Please verify your email address \n or reset password to send another link")
        }
      })
      .catch((error) => {
        reject(error.message)
        console.log(error, email, password)
      })
  })
}

export const getProfile = (id: string) => {
  return new Promise<ProfileData>((resolve, reject) => {
    const checkVerification = httpsCallable(functions, "checkVerification")
    getDoc(doc(firestore, "Users", id))
      .then((snapshot) => {
        let data = snapshot.data()
        !data && reject("No user data retrieved")
        !data?.email_address && reject("Profile error: Email error")
        !data?.first_name && reject("Profile error: First name error")
        !data?.last_name && reject("Profile error: Last name error ")
        !data?.role && reject("Profile error: Role error")
        !data?.emailVerified
          ? checkVerification({ uid: id })
              .then((res) => {
                const result = res.data as { emailVerified: boolean }
                resolve({ ...data, emailVerified: result.emailVerified, uid: id } as ProfileData)
              })
              .catch(reject)
          : resolve({ ...data, uid: id } as ProfileData)
      })
      .catch(reject)
  })
}

export const getAuthDetails = (id: string) => {
  return new Promise<AuthData>((resolve, reject) => {
    const ffAuthDetails = httpsCallable(functions, "getUserAuthDetails")
    ffAuthDetails(id)
      .then((res) => resolve(res.data as AuthData))
      .catch(reject)
  })
}

export const requestResetPasswordEmail = (email: string) => {
  const resetPasswordEmail = httpsCallable(functions, "resetPasswordEmail")
  resetPasswordEmail(email)
}

export const resendInvite = (uid: string, email: string) => {
  return new Promise((resolve, reject) => {
    const reinviteUser = httpsCallable(functions, "reinviteUser")
    reinviteUser({ userId: uid, email })
      .then((success) => {
        resolve(success)
      })
      .catch((error) => {
        sendPasswordResetEmail(auth, email)
        reject(error)
      })
  })
}

export const resetPassword = ({
  user,
  newPassword,
  currentPassword,
  clearTemp,
}: {
  user: User
  newPassword: string
  currentPassword: string
  clearTemp: boolean
}) => {
  return new Promise<true>((resolve, reject) => {
    if (user.email === null) {
      reject()
      return
    }
    const credential = EmailAuthProvider.credential(user.email, currentPassword)
    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            clearTemp &&
              updateDoc(doc(firestore, "Users", user.uid), {
                completed: true,
              })
            resolve(true)
          })
          .catch(() => {
            reject("Database Error")
            auth.signOut()
          })
      })
      .catch(() => {
        reject("Your current password is incorrect.")
      })
  })
}

export const verifyResetPasswordCode = (code: string) => {
  return new Promise((resolve, reject) => {
    verifyPasswordResetCode(auth, code).then(resolve).catch(reject)
  })
}

export const performPasswordReset = (code: string, password: string, email: string) => {
  return new Promise((resolve, reject) => {
    confirmPasswordReset(auth, code, password)
      .then(() => {
        if (email) {
          const updateUser = httpsCallable(functions, "userHasUpdatedPassword")
          updateUser(email)
            .then(() => {
              resolve(email)
            })
            .catch(reject)
        } else {
          resolve(true)
        }
      })
      .catch(reject)
  })
}
