import { useEffect, useState } from "react"
import Slider from "rc-slider"
import { Tooltip, Alert, AlertTitle } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { toHex, toInt } from "@covvi/common-functions"
import { useCurrentHandContext } from "@context/remoteAssistProviders"
import { Config } from "@util/parser/Parser"
import CVCardBody from "@ui/sections/CVCardBody"
import { stringVersionToInt } from "@util/commonFunctions/version"
import CVSubtitle from "@ui/text/CVSubtitle"

const DigitData = ({
  sendCommand,
  handConfig,
}: {
  sendCommand: (command: string) => void
  handConfig: Config | undefined
}) => {
  const { digitPositions } = useCurrentHandContext()
  const [showingFirmwareWarning, setShowingFirmwareWarning] = useState<boolean>(false)

  useEffect(() => {
    if (handConfig) {
      if (
        stringVersionToInt(handConfig?.params.version.display_value) < stringVersionToInt("5.1.25")
      ) {
        setShowingFirmwareWarning(true)
      }
    }
  }, [handConfig])

  return digitPositions ? (
    <>
      {showingFirmwareWarning && (
        <Alert sx={{ borderRadius: 3, marginBottom: "16px" }} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {ls.getText("firmware_digit_drive_warning")}
        </Alert>
      )}
      <CVCardBody roundedBottom={true} roundedTop={true}>
        <div className="m-4">
          <CVSubtitle
            text={ls.getText("Positions")}
            helperText={ls.getText("digit_positions_help")}
          />
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6">
            <DigitPositionIndicator
              position={digitPositions.thumbPosition}
              current={digitPositions.thumbCurrent}
              title={ls.getText("Thumb")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="01"
            />
            <DigitPositionIndicator
              position={digitPositions.indexPosition}
              current={digitPositions.indexCurrent}
              title={ls.getText("Index")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="02"
            />
            <DigitPositionIndicator
              position={digitPositions.middlePosition}
              current={digitPositions.middleCurrent}
              title={ls.getText("Middle")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="04"
            />
            <DigitPositionIndicator
              position={digitPositions.ringPosition}
              current={digitPositions.ringCurrent}
              title={ls.getText("Ring")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="08"
            />
            <DigitPositionIndicator
              position={digitPositions.littlePosition}
              current={digitPositions.littleCurrent}
              title={ls.getText("Little")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="10"
            />
            <DigitPositionIndicator
              position={digitPositions.rotationPosition}
              title={ls.getText("Rotate")}
              sendCommand={sendCommand}
              handConfig={handConfig}
              index="20"
            />
          </div>
        </div>
      </CVCardBody>

      <div className="mb-6" />

      <CVCardBody roundedBottom={true} roundedTop={true}>
        <div className="flex flex-col m-4">
          <CVSubtitle
            text={ls.getText("Voltages")}
            helperText={ls.getText("digit_voltages_help")}
          />
          <div className="flex flex-col w-full 2xl:w-1/2 font-medium">
            <span className="">
              {ls.getText("Battery")} - {digitPositions.batteryVoltage}mV
            </span>
            <span className="">
              {ls.getText("Channel")} A {ls.getText("Output")} {"- "}
              {digitPositions.channelAOuputVoltage}mV
            </span>
            <span className="">
              {ls.getText("Channel")} B {ls.getText("Output")} {"- "}
              {digitPositions.channelBOuputVoltage}mV
            </span>
          </div>
        </div>
      </CVCardBody>
    </>
  ) : (
    <div className="p-4">{ls.getText("Waiting to connect to hand")}</div>
  )
}

const DigitPositionIndicator = ({
  position,
  sendCommand,
  title,
  index,
  handConfig,
}: {
  position: number
  current?: number
  sendCommand: (command: string) => void
  title: string
  index: string
  handConfig: any
}) => {
  const { handStatus } = useCurrentHandContext()
  const [updating, setUpdating] = useState(false)
  const [updatingValue, setUpdatingValue] = useState<number | undefined>()
  const [marks, setMarks] = useState({})
  const [highestVal, setHighestVal] = useState<number>(0)

  const LARGE_FONT_SIZE = 17
  const SMALL_FONT_SIZE = 10

  useEffect(() => {
    if (!updating) {
      setUpdatingValue(position)
    }
  }, [position, updating])

  useEffect(() => {
    if (!handConfig) return
    let marksObj = {
      0: {
        style: { color: "grey", fontSize: SMALL_FONT_SIZE },
        label: 0,
      },
      [toInt(handConfig.params[`${title.toLowerCase()}_open_endpoint`].value)]: {
        style: { color: "black", fontSize: LARGE_FONT_SIZE },
        label: (
          <Tooltip title="Open Position" placement="right">
            <div>{toInt(handConfig.params[`${title.toLowerCase()}_open_endpoint`].value)}</div>
          </Tooltip>
        ),
      },
      [toInt(handConfig.params[`${title.toLowerCase()}_close_endpoint`].value)]: {
        style: { color: "black", fontSize: LARGE_FONT_SIZE },
        label: (
          <Tooltip title="Closed Position" placement="right">
            <div>{toInt(handConfig.params[`${title.toLowerCase()}_close_endpoint`].value)}</div>
          </Tooltip>
        ),
      },
      // 255: {
      //   style: { color: "grey", fontSize: SMALL_FONT_SIZE },
      //   label: 255,
      // },
    }
    if (
      handStatus?.currentGrip &&
      ["Tripod", "Precision Open", "Precision Closed", "User Grip #1"].includes(
        handStatus?.currentGrip
      )
    ) {
      if (title === "Thumb") {
        marksObj[toInt(handConfig.params[`pinch_thumb_pos`].value)] = {
          style: { color: "grey", fontSize: SMALL_FONT_SIZE },
          label: (
            <Tooltip title="Thumb Pinch" placement="right">
              <div>{toInt(handConfig.params[`pinch_thumb_pos`].value)}</div>
            </Tooltip>
          ),
        }
      }
      if (title === "Index") {
        marksObj[toInt(handConfig.params[`pinch_index_pos`].value)] = {
          style: { color: "grey", fontSize: SMALL_FONT_SIZE },
          label: (
            <Tooltip title="Index Pinch" placement="right">
              <div>{toInt(handConfig.params[`pinch_index_pos`].value)}</div>
            </Tooltip>
          ),
        }
      }
    }
    if (handStatus?.currentGrip && ["Tripod", "User Grip #1"].includes(handStatus?.currentGrip)) {
      if (title === "Middle") {
        marksObj[toInt(handConfig.params[`pinch_middle_pos`].value)] = {
          style: { color: "grey", fontSize: SMALL_FONT_SIZE },
          label: (
            <Tooltip title="Middle Pinch" placement="right">
              <div>{toInt(handConfig.params[`pinch_middle_pos`].value)}</div>
            </Tooltip>
          ),
        }
      }
    }
    if (
      handStatus?.currentGrip &&
      ["Precision Open", "Precision Closed"].includes(handStatus?.currentGrip)
    ) {
      if (title === "Rotate") {
        marksObj[toInt(handConfig.params[`pinch_rotate_1`].value)] = {
          style: { color: "grey", fontSize: SMALL_FONT_SIZE },
          label: (
            <Tooltip title="Rotate Pinch 1" placement="right">
              <div>{toInt(handConfig.params[`pinch_rotate_1`].value)}</div>
            </Tooltip>
          ),
        }
      }
      // marksObj[toInt(handConfig.params[`pinch_rotate_2`].value)] = {
      //   style: { color: "grey", fontSize: SMALL_FONT_SIZE },
      //   label: (
      //     <Tooltip title="Rotate Pinch 2" placement="right">
      //       <div>{toInt(handConfig.params[`pinch_rotate_2`].value)}</div>
      //     </Tooltip>
      //   ),
      // }
    }

    setMarks(marksObj)
  }, [handStatus, handConfig])

  useEffect(() => {
    marks &&
      setHighestVal(
        Object.keys(marks)
          .map((mark) => parseInt(mark))
          .sort((a, b) => b - a)[0]
      )
  }, [marks])

  return (
    <div className="h-60 flex flex-col rounded-[5px] border border-[#E9E9E9] m-[2px]">
      <div className="flex flex-row items-center justify-between m-[8px]">
        <div className="text-md">{title}</div>
        <div className="min-w-[45px] lg:min-w-[24px] xl:min-w-[45px] text-center text-sm xl:text-md rounded-[5px] border border-[#E9E9E9] px-[1px] xl:px-[6px] py-[1px] xl:py-[4px] ">{`${updatingValue
          ?.toString()
          .padStart(3, "0")}`}</div>
      </div>
      <div className="justify-items-center my-[16px] h-40 pl-[40%]">
        <Slider
          vertical={true}
          draggableTrack
          onChange={(value) => {
            if (!updating) {
              setUpdating(true)
            }
            setUpdatingValue(value as number)
          }}
          onAfterChange={(nextValues) => {
            setUpdating(false)
            sendCommand("TT0101")
            setTimeout(() => {
              sendCommand(`TM03${index}${toHex(nextValues as number)}64`)

              setTimeout(() => {
                sendCommand("TT0100")
              }, 1000)
            }, 100)
          }}
          value={updatingValue}
          min={0}
          max={highestVal}
          marks={marks}
          trackStyle={[{ backgroundColor: "#C57C46" }]}
          handleStyle={[{ backgroundColor: "#C57C46", borderColor: "#C57C46", opacity: 1 }]}
          dotStyle={{ display: "none" }}
        />
      </div>
    </div>
  )
}

export default DigitData
