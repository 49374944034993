import { useCurrentSessionContext } from "@context/remoteAssistProviders"
import { languageSelector as ls } from "@covvi/language-selector"
import { HandStatus } from "@typesFolder/remoteAssistTypes"
import { Config } from "@typesFolder/types"
import { stringVersionToInt } from "@util/commonFunctions/version"

export const CurrentGripConnection = ({
  handStatus,
  config,
}: {
  handStatus: HandStatus | undefined
  config: Config | undefined
}) => {
  const { client } = useCurrentSessionContext()

  const displayCurrentGrip = (currentGrip: string = "") => {
    if (config?.firmwareVersion) {
      if (stringVersionToInt(config.firmwareVersion) > stringVersionToInt("5.1.25")) {
        if (currentGrip === "User Grip #1") return "User Grip"
        return currentGrip
      } else if (config.userGrips) {
        if (currentGrip === "User Grip #1") {
          return config.userGrips[0]
        } else if (currentGrip === "No Grip" && config.userGrips[1] !== "No Grip") {
          return config.userGrips[1]
        } else if (currentGrip === "Tripod") {
          if (config?.userGrips[2] !== "No Grip") {
            return `Tripod (or ${config.userGrips[2]})`
          } else return currentGrip
        } else if (currentGrip === "Power") {
          if (config.userGrips[3] !== "No Grip") {
            return `Power (or ${config.userGrips[3]})`
          } else return currentGrip
        } else if (currentGrip === "Trigger") {
          if (config.userGrips[4] !== "No Grip") {
            return `Trigger (or ${config.userGrips[4]})`
          } else return currentGrip
        } else if (currentGrip === "Precision Open") {
          if (config.userGrips[5] !== "No Grip") {
            return `Precision Open (or ${config.userGrips[5]})`
          } else return currentGrip
        } else {
          return currentGrip
        }
      }
    } else return currentGrip
  }

  const displayCurrentTable = (currentTable: string) => {
    if (currentTable.startsWith("Table")) {
      return currentTable?.slice(-1)
    } else if (currentTable === "Direct") {
      return ls.getText("map_to_grip_name")
    }
  }

  return (
    <div className="w-full max-w-max flex flex-row-reverse border rounded bg-covviGrey border-lightCovviGrey px-8 py-2 text-darkerCovviGrey mb-4 sm:mb-8 self-center sm:self-auto">
      {client ? (
        <div className="flex flex-row space-x-4">
          {handStatus?.currentGrip && (
            <div className="flex flex-row">
              <p>Grip:&nbsp;</p>
              <p className="font-semibold">{displayCurrentGrip(handStatus.currentGrip)}</p>
            </div>
          )}
          {handStatus?.currentTable && (
            <div className="flex flex-row">
              <p>Table:&nbsp;</p>
              <p className="font-semibold">{displayCurrentTable(handStatus.currentTable)}</p>
            </div>
          )}
          {!handStatus && <p>{ls.getText("No Current Grip Data")}</p>}
        </div>
      ) : (
        <div className="flex flex-row mx-4">
          <p className="text-lg text-red-600">{ls.getText("User not connected")}</p>
        </div>
      )}
    </div>
  )
}
