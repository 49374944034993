import { languageSelector as ls } from "@covvi/language-selector"
import { Config } from "@util/parser/Parser"
import { Row } from "@typesFolder/types"
import { UserGrips } from "@typesFolder/remoteAssistTypes"

import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import CVSubtitle from "@ui/text/CVSubtitle"

const GripSwitchTriggers = ({
  config,
  content,
  updateParam,
  canEdit,
  userGrips,
}: {
  config: Config
  content: Row[]
  updateParam: Function
  canEdit: boolean
  userGrips: UserGrips | undefined
}) => {
  return (
    <>
      <div className="flex flex-col justify-between py-2">
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-10">
          {content
            .filter((row, i) => !row.name.startsWith("map_to_grip"))
            .map((row, i) => {
              return (
                <SelectInput
                  key={i}
                  value={config.params[row.name]}
                  setValue={updateParam}
                  canEdit={canEdit}
                />
              )
            })}
        </div>
        {content.filter((row, i) => row.name.startsWith("map_to_grip")).length !== 0 && (
          <>
            <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
            <CVSubtitle text={ls.getText("map_to_grip_name")} />
          </>
        )}
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-10 pt-5">
          {content
            .filter((row, i) => row.name.startsWith("map_to_grip"))
            .map((row, i) => {
              return (
                <SelectInput
                  key={i}
                  value={config.params[row.name]}
                  setValue={updateParam}
                  canEdit={canEdit}
                  userGrips={userGrips}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}

export default GripSwitchTriggers
