import ViewTranslation from "@components/modals/translation/ViewTranslation"
import { useAuth } from "@context/AuthContext"
import { languageSelector as ls } from "@covvi/language-selector"
import {
  DisplayTranslation,
  NewTableOptions,
  PossibleLangLong,
  PossibleLangShort,
  StoredTranslation,
  Translation,
} from "@typesFolder/types"
import Table from "@ui/table/Table"
import {
  languageShortToLong,
  languageToShort,
  languagesLong,
} from "@util/commonFunctions/languages"
import { truncateString } from "@util/commonFunctions/truncateString"
import {
  getTranslations,
  requestContext,
  updateTranslation,
} from "@util/firebase/translationFunctions"
import { useEffect, useState } from "react"

const Translations = () => {
  const { profile } = useAuth()
  const [translations, setTranslations] = useState<StoredTranslation[]>([])
  const [displayTranslations, setDisplayTranslations] = useState<DisplayTranslation[]>()
  const [showingCompleted, setShowingCompleted] = useState(true)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [currentTranslation, setCurrentTranslation] = useState<string | undefined>()
  const [languages, setLanguages] = useState<
    undefined | { long: PossibleLangLong[]; short: PossibleLangShort[] }
  >()

  const setTableOptions = (): NewTableOptions => {
    return {
      fieldIds: ["key", "en", ...languages!.short],
      fieldTitles: ["Key", "English", ...languages!.long],
      pillArray: {
        buttons: [
          { title: "Show Completed", onClick: () => setShowingCompleted(true) },
          { title: "Hide Completed", onClick: () => setShowingCompleted(false) },
        ],
        selected: showingCompleted ? "Show Completed" : "Hide Completed",
        setSelected: () => {},
      },
      onClick: (currentTrans: StoredTranslation) => setCurrentTranslation(currentTrans.key),
      searchParams: ["key", "en", ...languages!.short],
    }
  }

  const editTranslations = (res: StoredTranslation[]) => {
    const transString = (translation: Translation) => {
      if (translation === undefined) {
        return ""
      } else if (typeof translation === "string") {
        return truncateString(translation, 8)
      } else {
        return truncateString(translation.title, 8)
      }
    }
    return res.map((translation) => {
      return {
        ...translation,
        en: transString(translation.en.translation),
        zh: transString(translation.zh?.translation),
        ja: transString(translation.ja?.translation),
        es: transString(translation.es?.translation),
        tr: transString(translation.tr?.translation),
        ar: transString(translation.ar?.translation),
      }
    }, [])
  }

  const handleUpdate = (key: string, language: PossibleLangShort, translation: Translation) => {
    return new Promise<void>((resolve, reject) => {
      updateTranslation(profile!, language, key, translation)
        .then(() => {
          setTranslations((current) =>
            current.map((entry) =>
              entry.key === key
                ? { ...entry, [language]: { setBy: profile!.uid, setOn: Date.now(), translation } }
                : entry
            )
          )
          resolve()
        })
        .catch(() => reject(false))
    })
  }

  const getTranslationContext = async (translation: StoredTranslation) => {
    return new Promise<void>((resolve, reject) => {
      requestContext(translation, profile!.email_address)
        .then(() => resolve())
        .catch(() => reject())
    })
    // send profile.email, language and translation key to software@covvi.com, we'll send a screenshot or some contex to them
  }

  useEffect(() => {
    getTranslations().then((res) => setTranslations(res))
    let availableLanguages: PossibleLangLong[] =
      profile?.role === "Admin" ? languagesLong.slice(1) : profile?.languages || []
    setLanguages({
      long: availableLanguages,
      short: availableLanguages.length
        ? availableLanguages?.map((language) => languageToShort(language) as PossibleLangShort)
        : [],
    })
  }, [])

  useEffect(() => {
    if (!translations) {
      return
    } else if (showingCompleted) {
      setDisplayTranslations(editTranslations(translations))
    } else {
      const filteredTrans = translations.filter((entry) => {
        let allLangs = true
        languages!.short.forEach((lang) => !entry[lang]?.translation && (allLangs = false))
        if (!allLangs) {
          return entry
        }
      })
      setDisplayTranslations(editTranslations(filteredTrans))
    }
    setLoaded(true)
    return () => setLoaded(false)
  }, [translations, showingCompleted])

  return !profile?.languages?.length && profile?.role !== "Admin" ? (
    <div>{ls.getText("translation_permission")}</div>
  ) : (
    <>
      {displayTranslations && loaded && (
        <Table data={displayTranslations} tableOptions={setTableOptions()} />
      )}
      {languages && currentTranslation && (
        <ViewTranslation
          showingModal={!!currentTranslation}
          setShowingModal={setCurrentTranslation}
          translation={translations.filter((entry) => entry.key === currentTranslation)[0]}
          updateTranslation={handleUpdate}
          languages={languages}
          getContext={getTranslationContext}
        />
      )}
    </>
  )
}
export default Translations
