import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth, useReset } from "@context/AuthContext"
import { requestResetPasswordEmail, verifyResetPasswordCode } from "@util/firebase/authFunctions"

import ResetByEmailInput from "@components/sections/ResetByEmailInput"
import ResetPassword from "@components/sections/ResetPassword"

const AccountManagement = () => {
  const { user } = useAuth()
  const { setResetParams } = useReset()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [success, setSuccess] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  useEffect(() => {
    user && navigate("/")
  }, [user, navigate])

  useEffect(() => {
    const mode = searchParams.get("mode")
    const tempOobCode = searchParams.get("oobCode")
    !mode && navigate("/")
    if (mode === "resetPassword" && tempOobCode) {
      setTitle("Resetting Password")
      verifyResetPasswordCode(tempOobCode)
        .then((res) => {
          setResetParams((resetParams) => {
            return { ...resetParams, oobCode: tempOobCode, email: res as string }
          })
          setMessage(ls.getText("Please enter a new password"))
          setSuccess(true)
        })
        .catch((error) => {
          setMessage(ls.getText("code_problem"))
          setSuccess(false)
        })
    } else {
      setMessage(ls.getText("link_not_valid"))
      setSuccess(false)
    }
  }, [searchParams, navigate])

  return (
    <>
      {title === "Resetting Password" && (
        <div className="mx-auto my-auto">
          {success ? (
            <ResetPassword showingCurrent={false} />
          ) : (
            <ResetByEmailInput
              title="Reset Password"
              alert={message}
              submitForm={(email) => {
                requestResetPasswordEmail(email)
                navigate("/login")
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default AccountManagement
