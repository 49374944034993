import { IconButton, Popover } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { MouseEvent, ReactNode, useState } from "react"

export const MorePopover = ({ children }: { children: ReactNode }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div>
      <IconButton aria-describedby={id} size={"large"} onClick={handleClick}>
        <MoreVertIcon fontSize="inherit" color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children}
      </Popover>
    </div>
  )
}
