import { useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { requestAccountDeletion } from "@util/firebase/userFunctions"

import { TextField } from "@mui/material"
import BasicModal from "@ui/modals/BasicModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"

interface Props {
  showing: boolean
  setShowing: Function
  setAlert: Function
}

const ConfirmRequestDeletion = ({ showing, setShowing, setAlert }: Props) => {
  const { profile } = useAuth()
  const [reasonForDeletion, setReasonForDeletion] = useState<string>("")
  const [emailTextInput, setEmailTextInput] = useState<string>("")
  const [loading, setLoading] = useState(false)

  return (
    <BasicModal open={showing} setClosed={() => setShowing(false)} fullWidth={true}>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("request_deletion")} />
        <CVCloseModal setShowingModal={setShowing} />
      </CVCardHeader>
      <CVCardBody>
        <div className="flex flex-col p-[30px] min-w-[300px]">
          <p className="pb-[20px]">{`${ls.getText("request_deletion_of")}: ${
            profile?.email_address
          }`}</p>
          <p className="pb-[20px]">{ls.getText("reenter_your_email")}</p>
          <div className="pb-[20px] flex flex-row flex-1 basis-full">
            <TextField
              className="basis-full"
              placeholder={ls.getText("Email Address")}
              size="small"
              onChange={(e) => {
                setEmailTextInput(e.target.value)
              }}
            />
          </div>
          <TextField
            size="small"
            multiline
            minRows={3}
            onChange={(e) => {
              setReasonForDeletion(e.target.value)
            }}
            placeholder={ls.getText("Reason for Account Deletion")}
          />
        </div>
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={[
            {
              title: ls.getText("request_deletion"),
              color: "error",
              loading: loading,
              onClick: () => {
                setLoading(true)
                profile &&
                  requestAccountDeletion(profile, reasonForDeletion)
                    .then(() => {
                      setLoading(false)
                      setAlert("success")
                    })
                    .catch(() => {
                      setAlert("error")
                    })
              },
              disabled: profile?.email_address.toLowerCase() !== emailTextInput?.toLowerCase(),
              variant: "contained",
            },
            {
              variant: "outlined",
              onClick: () => setShowing(false),
              title: ls.getText("Cancel"),
            },
          ]}
        />
      </CVCardFooter>
    </BasicModal>
  )
}

export default ConfirmRequestDeletion
