import { useState } from "react"
import { InputBase } from "@mui/material"
import { Send } from "@mui/icons-material"

import { languageSelector as ls } from "@covvi/language-selector"

interface Props {
  send: Function
  isMultiline?: boolean
}

const CVChatInput = ({ send, isMultiline = false }: Props) => {
  const [message, setMessage] = useState<string>("")
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (message.length > 0) {
      send(message)
      setMessage("")
    }
  }

  return (
    <form
      className={`bg-gray-100 flex flex-row justify-between items-center rounded-lg w-10/12 my-[21px] m-auto  py-[20px] px-[30px] overflow-y-auto`}
      onSubmit={(e) => handleSubmit(e)}
    >
      <InputBase
        placeholder={ls.getText("msg_here") + "..."}
        className={`flex flex-1 bg-gray-100  rounded-lg px-[20px] outline-none`}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        multiline={isMultiline}
        minRows={1}
        maxRows={3}
        autoFocus={true}
      />
      <div
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        className="h-[44px] w-[44px] flex justify-center items-center"
        onClick={(e) => {
          if (message.length > 0) {
            send(message)
            setMessage("")
          }
        }}
      >
        <Send sx={{ color: isHovered ? "black" : "#9E9E9E" }} height={20} width={20} />
      </div>
    </form>
  )
}

export default CVChatInput
