import { languageSelector as ls } from "@covvi/language-selector"

import BasicModal from "@ui/modals/BasicModal"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"

interface Props {
  showingModal: boolean
  setShowingModal: Function
  currentRestrictedStatus: boolean
  confirm: () => void
}

const RestrictUserConfirmation = ({
  showingModal,
  setShowingModal,
  currentRestrictedStatus,
  confirm,
}: Props) => {
  return (
    <BasicModal open={showingModal} setClosed={() => setShowingModal(false)}>
      <CVCardHeader>
        <CVCardTitle
          title={ls.getText(currentRestrictedStatus ? "Unrestrict User" : "Restrict User")}
        />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col flex-1 space-y-2">
          <div>
            <p>
              {ls.getText(
                currentRestrictedStatus
                  ? "unrestrict_user_confirmation"
                  : "restrict_user_confirmation"
              )}
            </p>
            <br />
            <p>{`${ls.getText(
              currentRestrictedStatus ? "full_access_text" : "limit_access_text"
            )} ${ls.getText("restricted_mode_info")}`}</p>
          </div>
        </div>
      </CVCardBody>
      <CVCardFooter>
        <>
          <ButtonRow
            buttonArray={[
              {
                title: ls.getText(currentRestrictedStatus ? "Unrestrict User" : "Restrict User"),
                color: "error",
                variant: "contained",
                onClick: confirm,
              },
              {
                variant: "outlined",
                onClick: () => setShowingModal(false),
                title: ls.getText("Cancel"),
              },
            ]}
          />
        </>
      </CVCardFooter>
    </BasicModal>
  )
}

export default RestrictUserConfirmation
