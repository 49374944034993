import { Button } from "@mui/material"
import { languageSelector as ls } from "@covvi/language-selector"

interface Props {
  active: boolean
  onClick: () => void
  title: string
}

export const PillButton = ({ active, onClick, title }: Props) => {
  return (
    <div className="flex max-w-[256px] mx-auto">
      <Button
        sx={{ borderRadius: 28 }}
        variant={"contained"}
        fullWidth
        color={active ? "primary" : "secondary"}
        onClick={onClick}
        disableElevation={true}
      >
        {ls.getText(title)}
      </Button>
    </div>
  )
}
