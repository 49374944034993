import CVCardBody from "@ui/sections/CVCardBody"
import Spinner from "@ui/spinners/Spinner"
import { Fragment, useEffect, useRef, useState } from "react"
import logoMain from "@assets/images/logoMain.png"
import CVCardHeader from "@ui/sections/CVCardHeader"
import { languageSelector as ls } from "@covvi/language-selector"
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import ErrorMessage from "@ui/alerts/ErrorMessage"
import CVCardFooter from "@ui/sections/CVCardFooter"
import {
  getFeedbackQuestionnaire,
  uploadFeedbackResponse,
} from "@util/firebase/dataCollectionFunctions"
import { FeedbackPage, FeedbackQuestionnaire, FeedbackResponse } from "@typesFolder/types"
import { CheckCircle, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material"
import BasicModal from "@ui/modals/BasicModal"
import { useNavigate } from "react-router-dom"

const Feedback = () => {
  const navigation = useNavigate()

  const [feedback, setFeedback] = useState<FeedbackResponse>({ version: "", pages: [] })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")
  const [selected, setSelected] = useState<string>("User Details")
  const [sections, setSections] = useState<FeedbackPage[]>([])

  useEffect(() => {
    getFeedbackQuestionnaire()
      .then((res) => {
        const feedbackData = Object.entries(res as FeedbackQuestionnaire)
        const version = feedbackData && feedbackData.shift()?.[1]
        setFeedback((current) => {
          return {
            ...current,
            version: version,
          }
        })
        setSections([
          {
            title: "User Details",
            questions: [
              { q: "Email address", type: "input" },
              { q: "First name", type: "input" },
              { q: "Last name", type: "input" },
            ],
          },
          ...feedbackData[0][1],
        ])
      })
      .catch((e) => console.log({ e }))
  }, [])

  const submitFeedback = () => {
    setLoading(true)
    uploadFeedbackResponse({ feedback })
      .then(() => {
        setLoading(false)
        setSuccess("Thank you for giving us feedback!")
        setTimeout(() => navigation("/"), 1000)
      })
      .catch((e) => {
        setLoading(false)
        setError("Failed to upload feedback")
        console.log({ e })
      })
  }

  return !loading ? (
    <>
      <img
        className="mx-auto w-9/12 md:w-5/12 lg:w-3/12 mb-[50px] max-w-xs lg:max-w-md"
        src={logoMain}
        alt="Covvi Logo"
      />
      <div className="w-11/12 md:w-3/4 lg:w-1/2 mx-auto   ">
        <form onSubmit={(e) => e.preventDefault()}>
          <CVCardHeader>
            <div
              className={
                "text-gray-500 hover:text-black text-black/60 py-[15px] mx-[20px] cursor-pointer flex flex-row flex-1 mx-[30px] justify-between "
              }
            >
              <h2 className={"whitespace-nowrap mx-2"}>{ls.getText(selected)}</h2>
              <h2 className={"whitespace-nowrap mx-2"}>
                {` ${
                  sections.length &&
                  sections.map((section, i) => {
                    if (section.title === selected) {
                      return i + 1
                    }
                  })
                }/${sections.length}`.replaceAll(",", "")}
              </h2>
            </div>
          </CVCardHeader>
          <CVCardBody>
            <div className="flex flex-col gap-y-8 px-[30px] py-[30px] h-[400px]">
              {sections.length >= 1 &&
                sections
                  .filter((section) => section.title === selected)[0]
                  .questions.map((question, i) => {
                    let pageIndex = 0
                    sections.forEach((section, i) => {
                      if (section.title === selected) {
                        pageIndex = i
                      }
                    })
                    // console.log("P", pageIndex, "Q", questionIndex, feedback.pages?.[pageIndex]?.[questionIndex])
                    switch (question.type) {
                      case "yn":
                        return (
                          <div className="basis-full sm:basis-[47%]" key={i}>
                            <FormControl>
                              <FormLabel id={`${i}-radio`}>{question.q}</FormLabel>
                              <RadioGroup
                                aria-labelledby={`${i}-radio`}
                                defaultValue="No"
                                name="radio-buttons-group"
                                row
                                value={feedback.pages?.[pageIndex]?.[i] || "No"}
                                onChange={(e) => {
                                  setFeedback((current) => {
                                    return {
                                      ...current,
                                      pages: {
                                        ...current.pages,
                                        [pageIndex]: {
                                          ...current.pages[pageIndex],
                                          [i]: e.target.value,
                                        },
                                      },
                                    }
                                  })
                                }}
                              >
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )
                      default:
                        return (
                          <Fragment key={i}>
                            {question.type === "open" && (
                              <h2 className={"pb-4"}>{ls.getText(question.q)}</h2>
                            )}
                            <TextField
                              variant="outlined"
                              label={question.type === "input" ? ls.getText(question.q) : ""}
                              onChange={(e) => {
                                setFeedback((current) => {
                                  return {
                                    ...current,
                                    pages: {
                                      ...current.pages,
                                      [pageIndex]: {
                                        ...current.pages[pageIndex],
                                        [i]: e.target.value,
                                      },
                                    },
                                  }
                                })
                              }}
                              error={false}
                              value={feedback.pages?.[pageIndex]?.[i] || ""}
                              multiline={true}
                              rows={question.type === "open" ? 4 : 1}
                            />
                          </Fragment>
                        )
                    }
                  })}

              <ErrorMessage message={error} />
            </div>
          </CVCardBody>
          <CVCardFooter>
            {sections.length && selected && (
              <div className="flex flex-row flex-1 mx-[30px] justify-between">
                <Button
                  variant="outlined"
                  className=""
                  onClick={() =>
                    sections.forEach(
                      (obj, i) =>
                        selected === obj.title &&
                        sections[i - 1] &&
                        setSelected(sections[i - 1].title)
                    )
                  }
                  disabled={selected === sections[0].title}
                >
                  {ls.getText("Previous")}
                </Button>
                <Button
                  variant={
                    selected !== sections[sections.length - 1].title ? "outlined" : "contained"
                  }
                  className=""
                  onClick={() => {
                    selected !== sections[sections.length - 1].title
                      ? sections.forEach(
                          (obj, i) =>
                            selected === obj.title &&
                            sections[i + 1] &&
                            setSelected(sections[i + 1].title)
                        )
                      : submitFeedback()
                  }}
                >
                  {ls.getText(selected === sections[sections.length - 1].title ? "Submit" : "Next")}
                </Button>
              </div>
            )}
          </CVCardFooter>
        </form>
      </div>
      <BasicModal open={!!success} setClosed={() => setSuccess("")}>
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      </BasicModal>
    </>
  ) : (
    <Spinner />
  )
}

export default Feedback
