import { useState, useEffect, useRef } from "react"
import useWindowSize from "@util/hooks/useWindowSize"

interface Props {
  val: number
  maxInput: number
  className: string
}

const InputLevelBar = ({ val, maxInput, className }: Props) => {
  const windowSize = useWindowSize()
  const barRef = useRef<HTMLDivElement>(null)
  const [backgroundSegWidth, setBackgroundSegWidth] = useState<number>()

  const widthOfDashedBorders = 4

  useEffect(() => {
    barRef.current &&
      setBackgroundSegWidth((barRef.current?.clientWidth - widthOfDashedBorders) / 5)
  }, [windowSize])

  return (
    <div>
      <div ref={barRef} className="flex border-2 my-2 h-24 bg-neutral-100 rounded-md relative">
        <div
          className={`${className} relative`}
          style={{ width: `${Math.round((val / maxInput) * 100)}%` }}
        />
        <div className={`flex flex-row absolute h-[92px]`}>
          {[0, 1, 2, 3].map((box) => {
            return (
              <div
                key={box}
                className={`
                border-r border-dashed border-[#C4C4C4]`}
                style={{ marginLeft: `${backgroundSegWidth}px` }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default InputLevelBar
