import { Tooltip, Zoom } from "@mui/material"
import useWindowSize from "@util/hooks/useWindowSize"

interface Props {
  text: string | React.ReactNode
}

const HelpTextTooltip = ({ text }: Props) => {
  const { width } = useWindowSize()

  return width && width > 640 ? (
    <div className={`h-5 w-5 ml-2`}>
      <Tooltip
        componentsProps={{
          arrow: {
            sx: {
              color: "#418FC3",
            },
          },
          tooltip: {
            sx: {
              backgroundColor: "#418FC3",
              padding: 2,
              fontSize: 14,
              fontWeight: 200,
              borderRadius: 3,
            },
          },
        }}
        title={text}
        arrow
        TransitionComponent={Zoom}
        enterDelay={400}
        placement="right"
      >
        <div className={`h-5 w-5 rounded-full bg-[#418FC3] text-white text-center leading-5`}>
          ?
        </div>
      </Tooltip>
    </div>
  ) : (
    <></>
  )
}

export default HelpTextTooltip
