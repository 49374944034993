import { languageSelector as ls } from "@covvi/language-selector"

export const validateSerialNumber = (serialNumber: string) => {
  if (!serialNumber) {
    return ls.getText("No hand name")
  } else if (!["CV1", "DH1", "LN1", "RD1"].includes(serialNumber.slice(0, 3))) {
    return ls.getText("not_covvi_hand")
  } else if (!(serialNumber.length === 16)) {
    return ls.getText("Hand name is incorrect length")
  } else if (!!isNaN(Number(serialNumber.slice(-6)))) {
    return ls.getText("end_six_digits")
  } else {
    return undefined
  }
}
