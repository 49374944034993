import { languageSelector as ls } from "@covvi/language-selector"
import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch } from "@mui/material"

interface Props {
  groupTitle?: string
  options: { title: string; checked: boolean; setChecked: () => void }[]
}

export const ToggleGroup = ({ groupTitle, options }: Props) => {
  return (
    <FormControl component="fieldset" variant="standard">
      {groupTitle && <FormLabel component="legend">{ls.getText(groupTitle)}</FormLabel>}
      <FormGroup row>
        {options.map((option, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Switch
                  checked={option.checked}
                  onChange={() => option.setChecked()}
                  name={option.title}
                />
              }
              label={ls.getText(option.title)}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
