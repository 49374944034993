import ReturnsCard from "@components/cards/ReturnsCard"

const Returns = () => {
  return (
    <>
      <ReturnsCard />
    </>
  )
}

export default Returns
