import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { inviteUser } from "@util/firebase/userFunctions"
import { ProfileData } from "@typesFolder/types"

import UserCard from "@components/cards/UserCard"

import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import BasicModal from "@ui/modals/BasicModal"

interface Props {
  showingNewUser: boolean
  setShowingNewUser: Function
  setAlert: Function
  primaryLoading: boolean
  setPrimaryLoading: Function
}

const NewUserModal = ({
  showingNewUser,
  setShowingNewUser,
  setAlert,
  primaryLoading,
  setPrimaryLoading,
}: Props) => {
  const { profile } = useAuth()
  const navigate = useNavigate()

  const checkIsAuthed = useCallback(() => {
    profile && profile.role === "User" && navigate("/")
  }, [navigate, profile])

  useEffect(() => {
    checkIsAuthed()
  }, [checkIsAuthed])

  const createNewUser = (userData?: ProfileData) => {
    setPrimaryLoading(true)
    if (userData) {
      inviteUser(userData as ProfileData, profile!)
        .then(() => {
          setAlert({ text: ls.getText("New User Created"), severity: "success" })
          setShowingNewUser(false)
          setPrimaryLoading(false)
        })
        .catch((e) => {
          setAlert({
            text: ls.getText("There was an error creating this account"),
            severity: "error",
          })
          setPrimaryLoading(false)
        })
    }
  }

  return (
    <>
      <BasicModal open={showingNewUser} setClosed={() => setShowingNewUser(false)}>
        <CVCardHeader>
          <CVCardTitle title={ls.getText("Add a New User")} />
          <CVCloseModal setShowingModal={setShowingNewUser} />
        </CVCardHeader>
        <UserCard
          section="Add User"
          additionalBtns={[
            {
              title: ls.getText("Cancel"),
              variant: "outlined",
              onClick: () => setShowingNewUser(false),
            },
          ]}
          onSubmit={createNewUser}
          primaryLoading={primaryLoading}
        />
      </BasicModal>
    </>
  )
}

export default NewUserModal
