import { Navigate, useLocation } from "react-router-dom"

import { useAuth } from "@context/AuthContext"
import { Navigation } from "@typesFolder/types"

import Layout from "@components/layout/Layout"

const AuthedRoute = ({
  children,
  title,
  subTitle,
  requiresLogin = true,
}: {
  children: JSX.Element
  title?: string
  subTitle?: string
  requiresLogin?: boolean
}) => {
  const { profile, authLoading } = useAuth()
  const location = useLocation()

  const permissionMap = require("@assets/Navigation.json") as Navigation

  if (authLoading) {
    return <></>
  } else if (requiresLogin && !profile) {
    return <Navigate to="/login" replace />
  } else if (!requiresLogin && profile) {
    return <Navigate to="/" replace />
  } else if (!profile?.completed && location.pathname !== "/") {
    return <Navigate to="/" replace />
  } else if (profile?.dataConsent === undefined && location.pathname !== "/") {
    return <Navigate to="/" replace />
  } else if (title && permissionMap[title].userLevel.includes(profile!.role)) {
    return (
      <Layout title={title} subTitle={subTitle}>
        {children}
      </Layout>
    )
  } else {
    return <></>
  }
}

export default AuthedRoute
