import { languageSelector as ls } from "@covvi/language-selector"
import { SupportMessage } from "@typesFolder/supportTypes"

import CVMessageCard from "@ui/cards/CVMessageCard"

interface Props {
  messages: SupportMessage[] | undefined
}

const Correspondence = ({ messages }: Props) => {
  return messages?.length ? (
    <div className="min-h-[300px] max-h-[500px">
      {messages?.map((message, index) => {
        return <CVMessageCard key={index} message={message} />
      })}
    </div>
  ) : (
    <div className="min-h-[200px]">
      <div
        className="bg-gray-50 rounded-xl
    py-[20px] px-[30px] w-10/12 min-h-[95px] my-[20px] m-auto"
      >
        {ls.getText("No messages")}
      </div>
    </div>
  )
}

export default Correspondence
