import { useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import Graph from "@ui/graphs/Graph"
import CVKeyValueInfoCard from "@ui/cards/CVKeyValueInfoCard"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import CVCardBody from "@ui/sections/CVCardBody"

interface Props {
  dataUsage: { title: string; data: { [key: string]: number } }
  dataGrips: { title: string; data: { [key: string]: number } }
  dataTriggers: { title: string; data: { [key: string]: number } }
  dataCounter: { title: string; data: { [key: string]: number } }
  dataLimits: { title: string; data: { [key: string]: number } }
}

const StatsOverview = ({ dataUsage, dataGrips, dataTriggers, dataCounter, dataLimits }: Props) => {
  const [selected, setSelected] = useState<string>("Data Usage")

  return (
    <>
      <div className="flex flex-col flex-1">
        <SecondaryTabArray
          buttonArray={[dataUsage, dataGrips, dataTriggers, dataCounter, dataLimits]}
          selected={selected}
          setSelected={setSelected}
          isWithinCard={false}
        />
        {["Data Usage", "Data Grips", "Data Triggers", "Data Counter"].includes(selected) && (
          <div className="w-full mt-4 flex flex-col flex-1">
            <CVCardBody roundedTop={true} roundedBottom={true}>
              <div className="flex flex-col flex-1">
                <Graph
                  graphData={
                    [dataUsage, dataGrips, dataTriggers, dataCounter].filter(
                      (tab) => tab.title === selected
                    )[0]
                  }
                  pageTitle={selected}
                  isLogarithmic={true}
                />
              </div>
            </CVCardBody>
          </div>
        )}
        {selected === "Data Limits" && (
          <div className="mt-4">
            <CVCardBody roundedTop={true} roundedBottom={true}>
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-[30px] p-12">
                <CVKeyValueInfoCard
                  title={ls.getText("max_temp")}
                  value={`${dataLimits.data.max_temp} °C`}
                />
                <CVKeyValueInfoCard
                  title={ls.getText("max_humidity")}
                  value={`${dataLimits.data.max_humidity} %`}
                />
                <CVKeyValueInfoCard
                  title={ls.getText("max_g_force")}
                  value={(dataLimits.data.max_g_force / 1000).toFixed(2).toString()}
                />
                <CVKeyValueInfoCard
                  title={ls.getText("max_battery")}
                  value={`${dataLimits.data.max_battery} mV`}
                />
                <CVKeyValueInfoCard
                  title={ls.getText("min_battery")}
                  value={`${dataLimits.data.min_battery} mV`}
                />
              </div>
            </CVCardBody>
          </div>
        )}
      </div>
    </>
  )
}

export default StatsOverview
