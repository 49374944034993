import validator from "validator"
import { languageSelector as ls } from "@covvi/language-selector"
import { Cancel, CheckCircle } from "@mui/icons-material"

export const PasswordCheck = ({ password, type }: { password: string; type: string }) => {
  const passes = () => {
    switch (type) {
      case "pass_8_50":
        return !(password.length >= 8 && password.length <= 50)
      case "pass_must_upper":
        return validator.isLowercase(password)
      case "pass_no_special":
        return !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
      case "pass_no_number":
        return !/\d/.test(password)
      default:
        return false
    }
  }
  return (
    <div className="flex flex-row space-x-2">
      {passes() ? <Cancel color="primary" /> : <CheckCircle color="primary" />}
      <h2>{ls.getText(type)}</h2>
    </div>
  )
}
