import { useState, useEffect, ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useAuth } from "@context/AuthContext"
import { getHandConfigs, getLatestErrors, getStats } from "@util/firebase/handFunctions"
import { Config, DefaultObject, ErrorCodeData, HandTabs, NewTableOptions } from "@typesFolder/types"
import {
  DataCounter,
  DataLimits,
  DataTriggers,
  DataUsage,
  DataGrips,
  Statistics,
} from "@typesFolder/statsTypes"
import useWindowSize from "@util/hooks/useWindowSize"
import StatsOverview from "@components/sections/StatsOverview"
import StatsGeneral from "@components/sections/StatsGeneral"
import Spinner from "@ui/spinners/Spinner"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import ErrorLog from "@components/sections/additionalTools/ErrorLog"
import Table from "@ui/table/Table"

interface GraphData {
  title: string
  data: { [key: string]: number }
}
type Section = { title: string; data: { [key: string]: number } }[] | undefined

const ViewHand = () => {
  const { width = 1024 } = useWindowSize()
  const location = useLocation()
  const navigate = useNavigate()
  const { profile } = useAuth()
  const isCovvi = [
    "Sales Team Member",
    "Customer Service Team Member",
    "Tech Team Member",
    "Admin",
  ].includes(profile!.role)

  const [serialNumber, setSerialNumber] = useState<string>("")
  const [configHistories, setConfigHistories] = useState<Config[]>([])

  const [configDataLoading, setConfigDataLoading] = useState<boolean>(true)
  const [content, setContent] = useState<ReactNode>()

  const [stats, setStats] = useState(new Map<string, Statistics>())
  const [gripTimes, setGripTimes] = useState<undefined | GraphData[]>()
  const [gripCloses, setGripCloses] = useState<undefined | GraphData[]>()
  const [triggers, setTriggers] = useState<undefined | GraphData[]>()
  const [counters, setCounters] = useState<undefined | GraphData[]>()
  const [handErrors, setHandErrors] = useState<ErrorCodeData[]>()
  const [tabName, setTabName] = useState<HandTabs>("Configs")
  const [approxNumGraphVals, setApproxNumGraphVals] = useState<number>(20)

  useEffect(() => {
    width && setApproxNumGraphVals(Math.floor(width / 100))
  }, [width])

  useEffect(() => {
    location && setSerialNumber(location.pathname.split("/")[2])
  }, [location])

  useEffect(() => {
    if (serialNumber === "" || !profile) return
    !configDataLoading && setConfigDataLoading(true)
    const fetchData = async () => {
      let configHistories = await getHandConfigs(serialNumber, profile)
      let statsErrorProps: {
        handId: string
        uid: string
        role: string
        associatedUsers?: string[]
      } = { handId: serialNumber, uid: profile.uid, role: profile.role }
      profile.role === "Clinician" &&
        (statsErrorProps = { ...statsErrorProps, associatedUsers: profile.associated_users })

      if (!isCovvi) {
        configHistories = configHistories.filter((config) => !config.name?.startsWith("ARCHIVED"))
      }

      setConfigHistories(configHistories)
      setConfigDataLoading(false)
      getStats(statsErrorProps).then(
        (fbStats) =>
          (isCovvi || (fbStats as Map<string, Statistics>).size > 1) &&
          setStats(fbStats as Map<string, Statistics>)
      )
      getLatestErrors(statsErrorProps).then((res) => setHandErrors(res))
    }

    fetchData().catch((error) => console.error("get details failed", error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber])

  useEffect(() => {
    const sortSection = (data: Section) => {
      return data && data.length
        ? [...data].sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
        : undefined
    }
    if (tabName === "Configs") {
      const tableOptions: NewTableOptions = {
        fieldIds: ["name", "date", ...(width > 640 ? ["firmwareVersion"] : [])],
        fieldTitles: ["Name", "Updated", ...(width > 640 ? ["Firmware Version"] : [])],
        onClick: (data: DefaultObject) => navigate(`/hands/${serialNumber}/${data.name}`),
        searchParams: ["name", "setBy", ...(width > 640 ? ["firmwareVersion"] : [])],
      }
      setContent(
        configDataLoading ? (
          <Spinner />
        ) : (
          <Table data={configHistories} tableOptions={tableOptions} />
        )
      )
      return
    } else if (tabName === "Errors" && handErrors) {
      setContent(<ErrorLog errorDataArray={handErrors} latestTechError={1} />)
      return
    } else if (tabName === "Overview" && stats) {
      const prev = stats.size >= 2 ? Array.from(stats)[stats.size - 2] : Array.from(stats)[0]
      const last = stats.size ? Array.from(stats)[stats.size - 1] : prev
      const overviewArray = [
        "data_usage",
        "data_grips",
        "data_triggers",
        "data_counter",
        "data_limits",
      ].map((characteristic) =>
        Object.entries(last[1][characteristic as keyof Statistics]).reduce((accum, entry) => {
          const previousValue =
            prev[1][characteristic as keyof Statistics][
              entry[0] as keyof (DataUsage | DataGrips | DataTriggers | DataCounter | DataLimits)
            ]

          let value = 0
          if (
            ["Admin", "Sales Team Member"].includes(profile!.role) ||
            characteristic === "data_limits"
          ) {
            value = entry[1]
          } else if (entry[1] > previousValue) {
            value = entry[1] - previousValue
          }
          return {
            ...accum,
            [entry[0]]: value,
          }
        }, {})
      )

      const dataUsage: { [key: string]: number } = { ...overviewArray[0] }
      if (!isCovvi) {
        delete dataUsage.idle
        delete dataUsage.powered_on
      }

      setContent(
        <StatsOverview
          dataUsage={{ title: "Data Usage", data: dataUsage }}
          dataGrips={{ title: "Data Grips", data: overviewArray[1] }}
          dataTriggers={{ title: "Data Triggers", data: overviewArray[2] }}
          dataCounter={{ title: "Data Counter", data: overviewArray[3] }}
          dataLimits={{ title: "Data Limits", data: overviewArray[4] }}
        />
      )
    } else if (tabName === "Grip Time" && stats) {
      const sections = gripTimes || extractStat("data_usage")
      setContent(<StatsGeneral title={tabName} sections={sortSection(sections)} />)
    } else if (tabName === "Grip Closes") {
      const sections = gripCloses || extractStat("data_grips")
      setContent(<StatsGeneral title={tabName} sections={sortSection(sections)} />)
    } else if (tabName === "Triggers" && stats) {
      const sections = triggers || extractStat("data_triggers")
      setContent(<StatsGeneral title={tabName} sections={sortSection(sections)} />)
    } else if (tabName === "Counters" && stats) {
      let sections = counters || extractStat("data_counter")

      if (!isCovvi) {
        let indexOfDeepSleep: number = -1
        sections.forEach((section, index) => {
          if (section.title === "deep_sleep") indexOfDeepSleep = index
        })

        sections = sections.slice(0, indexOfDeepSleep).concat(sections.slice(indexOfDeepSleep + 1))
      }

      setContent(<StatsGeneral title={tabName} sections={sortSection(sections)} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configDataLoading, stats, tabName, approxNumGraphVals])

  const extractStat = (characteristic: keyof Statistics) => {
    let data: { [key: string]: number[] } = {}
    let dataDates: number[] = []
    interface miniDateObj {
      index: number
      date: number
    }
    let minimizedDateList: miniDateObj[] = []
    Object.entries(Object.fromEntries(stats)).forEach(([date, snapshot], index) => {
      const prev = Object.entries(Object.fromEntries(stats))[index - 1]
      if (snapshot[characteristic] !== undefined && date) {
        dataDates.push(parseInt(date))
        Object.entries(snapshot[characteristic]).forEach(([stat, value]) => {
          let previousValue = 0
          if (
            index === 0 ||
            prev[stat as keyof (DataUsage | DataGrips | DataTriggers | DataCounter)] === undefined
          ) {
            previousValue = value
          } else if (prev) {
            previousValue =
              prev[1][characteristic][
                stat as keyof (DataUsage | DataGrips | DataTriggers | DataCounter)
              ]
          }

          let displayValue = !(value > previousValue) ? value : value - previousValue
          ;["Admin", "Customer Service Team Member", "Tech Team Member"].includes(profile!.role) &&
            (displayValue = value)
          data[stat] ? data[stat].push(displayValue) : (data[stat] = [displayValue])
        })
      }
    })

    // Reducing number of graph entries for non admin
    if (profile?.role !== "Admin") {
      let toNotBeRemoved: number[] = []

      if (dataDates.length > approxNumGraphVals) {
        dataDates.forEach((date, index) => {
          if ((index / Math.ceil(dataDates.length / approxNumGraphVals)) % 1 === 0) {
            toNotBeRemoved.push(index)
          }
        })

        toNotBeRemoved.forEach((index) => {
          minimizedDateList.push({ index, date: dataDates[index] })
        })
      }
    }
    const dataSet = Object.entries(data).map((entry) => {
      return {
        title: entry[0],
        data:
          profile?.role === "Admin"
            ? dataDates.reduce((accum, date, i) => ({ ...accum, [date]: entry[1][i] }), {})
            : minimizedDateList.reduce(
                (accum, date, i) => ({ ...accum, [date.date]: entry[1][date.index] }),
                {}
              ),
      }
    })

    if (characteristic === "data_usage") setGripTimes(dataSet)
    if (characteristic === "data_grips") setGripCloses(dataSet)
    if (characteristic === "data_counter") setCounters(dataSet)
    if (characteristic === "data_triggers") setTriggers(dataSet)
    return dataSet
  }

  const pillButtons = () => {
    const pillArray: HandTabs[] = ["Configs"]
    stats.size && pillArray.push("Overview", "Grip Time", "Grip Closes", "Counters", "Triggers")
    handErrors && handErrors.length && pillArray.push("Errors")
    return pillArray.map((pill) => {
      return { title: pill, onClick: () => setTabName(pill) }
    })
  }

  return (
    <>
      <PillButtonArray
        pillArray={pillButtons()}
        selected={tabName}
        setSelected={setTabName}
        extraMarginBottom={true}
      />
      {content}
    </>
  )
}

export default ViewHand
