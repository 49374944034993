import { languageSelector as ls } from "@covvi/language-selector"
import { TextField } from "@mui/material"
import {
  DisplayTranslation,
  PossibleLangLong,
  PossibleLangShort,
  StoredTranslation,
  Translation,
} from "@typesFolder/types"
import ButtonWithLoading from "@ui/buttons/ButtonWithLoading"
import BasicModal from "@ui/modals/BasicModal"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import { languageShortToLong } from "@util/commonFunctions/languages"
import React, { useEffect, useState } from "react"

const ViewTranslation = ({
  translation,
  updateTranslation,
  showingModal,
  setShowingModal,
  languages,
  getContext,
}: {
  showingModal: boolean
  setShowingModal: Function
  languages: { long: PossibleLangLong[]; short: PossibleLangShort[] }
  translation: StoredTranslation
  updateTranslation: (
    uid: string,
    language: PossibleLangShort,
    translation: Translation
  ) => Promise<void>
  getContext: (translation: StoredTranslation) => Promise<void>
}) => {
  interface TypeAndTranslations extends DisplayTranslation {
    type: "text" | "alert"
  }

  const [translations, setTranslations] = useState<TypeAndTranslations>({
    key: translation.key,
    en: translation.en.translation,
    type: typeof translation.en.translation === "string" ? "text" : "alert",
  })

  const [loading, setLoading] = useState<undefined | { [lang: string]: boolean }>()
  const [loadingContext, setLoadingContext] = useState(false)
  const [status, setStatus] = useState<undefined | string>()

  useEffect(() => {
    languages.short.forEach((language) => {
      setLoading((current) => {
        return {
          ...current,
          [language]: false,
        }
      })
      setTranslations((current) => {
        return { ...current, [language]: translation[language]?.translation }
      })
    })
  }, [translation])

  const handleUpdate = async (lang: PossibleLangShort) => {
    setLoading((current) => {
      return {
        ...current,
        [lang]: true,
      }
    })
    await updateTranslation(translation.key, lang as PossibleLangShort, translations[lang])
      .then(() =>
        setStatus(`${ls.getText(languageShortToLong(lang))} ${ls.getText("trans_updated")}`)
      )
      .catch(() => setStatus(ls.getText("trans_error")))
    setLoading((current) => {
      return {
        ...current,
        [lang]: false,
      }
    })
  }

  const handleContextRequest = async () => {
    setLoadingContext(true)
    await getContext(translation)
      .then(() => setStatus(ls.getText("Context requested")))
      .catch(() => setStatus(ls.getText("context_error")))
    setLoadingContext(false)
  }

  return (
    <BasicModal open={showingModal} setClosed={() => setShowingModal()} fullWidth={true}>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Edit Translation")} />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col flex-1 space-y-2">
          {translations &&
            Object.entries(translations).map(([k, v], i) => {
              const key = k as keyof TypeAndTranslations
              const entry = v as Translation
              if (key !== "key" && key !== "type") {
                if (key === "en") {
                  return (
                    <div className="flex flex-row space-x-2 pb-4" key={i}>
                      {typeof entry === "string" ? (
                        <div className="font-bold text-lg">{entry}</div>
                      ) : (
                        <div>
                          <div className="font-bold text-lg">
                            {ls.getText("Title")}: {entry?.title || ""}
                          </div>
                          <div className="font-bold text-lg">
                            {ls.getText("Message")}: {entry?.message || ""}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                } else if (typeof entry === "string" || translations.type === "text") {
                  return (
                    <div className="flex flex-1 space-x-2" key={i}>
                      <TextField
                        className="basis-full  self-end"
                        variant="outlined"
                        label={ls.getText(languageShortToLong(key as PossibleLangShort))}
                        value={entry || ""}
                        onChange={(e) =>
                          setTranslations((current) => {
                            return { ...current, [key]: e.target.value }
                          })
                        }
                      />
                      <div className="my-auto flex flex-row">
                        <ButtonWithLoading
                          title={ls.getText("Update")}
                          loading={(loading && loading[key as PossibleLangShort]) || false}
                          onClick={() => handleUpdate(key as PossibleLangShort)}
                        />
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="flex flex-1 space-x-2" key={i}>
                      <div className="flex flex-col w-full space-y-2">
                        <TextField
                          className="basis-full 	"
                          variant="outlined"
                          label={`${ls.getText(
                            languageShortToLong(key as PossibleLangShort)
                          )} - ${ls.getText("Title")}`}
                          value={entry?.title || ""}
                          onChange={(e) =>
                            setTranslations((current) => {
                              const currentObj = current[key] as { title: string; message?: string }
                              return { ...current, [key]: { ...currentObj, title: e.target.value } }
                            })
                          }
                        />
                        <TextField
                          className="basis-full "
                          variant="outlined"
                          label={`${ls.getText(
                            languageShortToLong(key as PossibleLangShort)
                          )} - ${ls.getText("Message")}`}
                          value={entry?.message || ""}
                          onChange={(e) =>
                            setTranslations((current) => {
                              const currentObj = current[key] as { title: string; message?: string }
                              return {
                                ...current,
                                [key]: { ...currentObj, message: e.target.value },
                              }
                            })
                          }
                        />
                      </div>
                      <div className="my-auto flex flex-row">
                        <ButtonWithLoading
                          title={ls.getText("Update")}
                          loading={(loading && loading[key as PossibleLangShort]) || false}
                          onClick={() => handleUpdate(key as PossibleLangShort)}
                        />
                      </div>
                    </div>
                  )
                }
              } else {
                return <React.Fragment key={i}></React.Fragment>
              }
            })}
        </div>
      </CVCardBody>
      <CVCardFooter>
        <div className="px-8 spacing-x-4 flex flex-row">
          <ButtonWithLoading
            title={ls.getText("Context")}
            loading={loadingContext}
            onClick={() => handleContextRequest()}
          />
          <div className="px-4 my-auto">{status}</div>
        </div>
        <ButtonRow
          buttonArray={[
            {
              variant: "outlined",
              onClick: () => setShowingModal(),
              title: ls.getText("Close"),
            },
          ]}
        />
      </CVCardFooter>
    </BasicModal>
  )
}

export default ViewTranslation
