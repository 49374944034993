interface Props {
  title: string
}

const CVCardTitle = ({ title }: Props) => {
  return (
    <div className="my-auto ml-[30px] lg:mx-[30px] font-bold ">
      <h1 className="text-xl whitespace-nowrap">{title}</h1>
    </div>
  )
}

export default CVCardTitle
