import validator from "validator"

import { languageSelector as ls } from "@covvi/language-selector"

export const validatePassword = (newPass: string, verifyPass: string) => {
  if (newPass !== verifyPass) {
    return ls.getText("pass_no_match")
  } else if (newPass.length < 8 || newPass.length > 50) {
    return ls.getText("pass_8_50")
  } else if (validator.isLowercase(newPass)) {
    return ls.getText("pass_must_upper")
  } else if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPass)) {
    return ls.getText("pass_no_special")
  } else if (!/\d/.test(newPass)) {
    return ls.getText("pass_no_number")
  }
}
