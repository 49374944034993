import { languageSelector as ls } from "@covvi/language-selector"
import { Button } from "@mui/material"
import { MorePopover } from "@ui/buttons/MorePopover"

export const TableButtons = ({
  main,
  more,
}: {
  main: { title: string; onClick: () => void }
  more?: { title: string; onClick: () => void }
}) => {
  return (
    <div className="flex w-full justify-end space-x-2 min-h-[52px] lg:px-[25px]">
      <div className="flex w-full max-w-[256px]">
        <Button variant="contained" fullWidth onClick={() => main.onClick()}>
          {ls.getText(main.title)}
        </Button>
      </div>
      {more && (
        <MorePopover>
          <Button onClick={() => more.onClick()}>{ls.getText(more.title)}</Button>
        </MorePopover>
      )}
    </div>
  )
}
