import { languageSelector as ls } from "@covvi/language-selector"
import { Button } from "@mui/material"
import { ButtonObj } from "@typesFolder/types"
import ButtonWithLoading from "@ui/buttons/ButtonWithLoading"
import useWindowSize from "@util/hooks/useWindowSize"

export const ButtonRow = ({ buttonArray }: { buttonArray: ButtonObj[] }) => {
  const windowSize = useWindowSize()
  return (
    <div className="py-[28px] gap-x-4 gap-y-[20px] flex flex-1 flex-row-reverse flex-grow flex-wrap whitespace-nowrap justify-between mx-[30px]">
      {buttonArray.map((button, i) =>
        button.loading ? (
          <ButtonWithLoading
            key={i}
            title={button.title}
            variant={button.variant}
            color={button.color || "primary"}
            loading={button.loading}
            disabled={button.disabled}
            onClick={() => {
              button.onClick()
            }}
          />
        ) : (
          <Button
            key={i}
            variant={button.variant}
            color={button.color || "primary"}
            disableElevation
            disabled={button.disabled}
            onClick={() => button.onClick()}
          >
            {ls.getText(button.title)}
          </Button>
        )
      )}
    </div>
  )
}
