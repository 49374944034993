import { useState } from "react"
import { TextField, MenuItem } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { addReturn } from "@util/firebase/returnsFunctions"

import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"

const REGION_OPTIONS = [
  "United Kingdom (UK)",
  "United States (US)",
  "Canada",
  "Europe",
  "Rest Of World",
]
const QUANTITY_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9]
const PRODUCT_OPTIONS = ["COVVI HAND (Left)", "COVVI HAND (Right)", "SDU", "Other"]
const SERVICE_OPTIONS = ["Repair", "Upgrade", "Annual Service", "Other"]

interface ErrorObj {
  [key: string]: boolean
}

const ReturnsCard = () => {
  const { profile } = useAuth()

  const [showingError, setShowingError] = useState<boolean>(false)

  const [serialNumber, setSerialNumber] = useState<string>("")
  const [orderNumber, setOrderNumber] = useState<string>("")
  const [notes, setNotes] = useState<string>("")
  const [product, setProduct] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(1)
  const [region, setRegion] = useState<string>("")
  const [service, setService] = useState<string>("")
  const [success, setSuccess] = useState<boolean>(false)
  const [errors, setErrors] = useState<ErrorObj>({})

  const submitForm = () => {
    let isValid = validateForm()

    if (isValid === false) {
    } else {
      //   .addReturn({
      //     serialNumber,
      //     orderNumber,
      //     notes,
      //     product,
      //     quantity,
      //     region,
      //     service,
      //     profile ??? //
      //   })
      //   .then(() => {
      setSuccess(true)
      //   })
      //   .catch((error) => {
      //     console.error(error)
      //     alert(
      //       "There was an error with this request. Please try again later or contact COVVI Customer Services."
      //     )
      //   })
    }
  }

  const validateForm = () => {
    let validationErrors: ErrorObj = {}

    if (product === "") {
      validationErrors.product = true
    }
    if (region === "") {
      validationErrors.region = true
    }
    if (serialNumber === "") {
      validationErrors.serialNumber = true
    }
    if (service === "") {
      validationErrors.service = true
    }
    let isError = false
    for (const prop in validationErrors) {
      if (validationErrors[prop as keyof typeof errors] === true) {
        isError = true
      }
    }
    setErrors({ ...validationErrors })

    return !isError
  }

  return (
    <>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Submit a New Return")} />
      </CVCardHeader>
      <CVCardBody>
        <div className="flex flex-1 flex-col p-[30px] gap-y-[30px]">
          {success ? (
            <p className="mx-[30px] text-lg">{ls.getText("return_request_sent")}</p>
          ) : (
            <>
              <TextField
                className=""
                error={errors.product}
                helperText={errors.product ? ls.getText("Please Choose a Product") : ""}
                variant="outlined"
                label={ls.getText("Product")}
                value={product}
                select
                onChange={(e) => setProduct(e.target.value)}
                required
              >
                <MenuItem disabled value="">
                  {ls.getText("Select a Product")}
                </MenuItem>
                {PRODUCT_OPTIONS.map((option, index) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className=""
                variant="outlined"
                label={ls.getText("Quantity")}
                value={quantity}
                select
                onChange={(e) => setQuantity(Number(e.target.value))}
              >
                {QUANTITY_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className=""
                error={errors.region}
                helperText={errors.region ? ls.getText("Please Choose a Region") : ""}
                variant="outlined"
                label={ls.getText("Region")}
                value={region}
                select
                onChange={(e) => setRegion(e.target.value)}
                required
              >
                <MenuItem disabled value="">
                  {ls.getText("Select a Region")}
                </MenuItem>
                {REGION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className=""
                variant="outlined"
                label={ls.getText("Order/ Invoice Number")}
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
              <TextField
                className=""
                error={errors.serialNumber}
                helperText={errors.serialNumber ? ls.getText("Please Enter a Serial Number") : ""}
                variant="outlined"
                label={ls.getText("Serial Number")}
                value={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <TextField
                className=""
                error={errors.service}
                helperText={errors.service ? ls.getText("Please Choose a Service") : ""}
                variant="outlined"
                label={ls.getText("Service Required")}
                value={service}
                select
                onChange={(e) => setService(e.target.value)}
                required
              >
                <MenuItem disabled value="">
                  {ls.getText("Select a Service")}
                </MenuItem>
                {SERVICE_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className=""
                variant="outlined"
                label={ls.getText("Notes")}
                value={notes}
                multiline
                minRows={3}
                onChange={(e) => setNotes(e.target.value)}
              />
            </>
          )}
        </div>
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={[
            {
              variant: "contained",
              title: success ? "✔" : ls.getText("Submit"),
              disabled: success,
              onClick: success ? () => {} : () => submitForm(),
            },
          ]}
        />
      </CVCardFooter>
    </>
  )
}

export default ReturnsCard
