import { languageSelector as ls } from "@covvi/language-selector"
import { Alert, AlertTitle } from "@mui/material"

export const AlertSection = ({
  title,
  content,
  severity,
}: {
  title: string
  content: string
  severity: "success" | "info" | "warning" | "error"
}) => {
  return (
    <Alert severity={severity}>
      <AlertTitle>{ls.getText(title)}</AlertTitle>
      {ls.getText(content)}
    </Alert>
  )
}
