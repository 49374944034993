import { useState, useEffect } from "react"

import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { Config } from "@util/parser/Parser"
import { toInt, toHex } from "@covvi/common-functions"
import useWindowSize from "@util/hooks/useWindowSize"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

const NumberInput = ({
  config,
  value,
  setValue,
  canEdit = true,
  helperText,
}: {
  config: Config
  value: any
  setValue: Function
  canEdit?: boolean
  helperText?: string
}) => {
  const { width } = useWindowSize()
  const { profile } = useAuth()
  const [val, setVal] = useState<any>()
  const paramName = value.param_name
  const coconTriggerIsSet =
    config && config.params["single_co_contraction"].value !== "00" ? true : false

  useEffect(() => setVal(toInt(value.value)), [value])
  useEffect(() => {
    if (val >= 0 && val !== toInt(value.value)) setValue(paramName, toHex(val, value.length))
  }, [val])

  if (value.display_value === undefined) {
    profile?.role === "Admin" && console.log(value, "undefined value")
  }

  let increment: number =
    value.param_type === "INPUT_PERCENTAGE" ? 100 : parseInt(value.step || "10")
  let rangeMin: number = parseInt(value.minimum || "0") || 0
  let rangeMax: number = parseInt(value.maximum || "0") || 0

  if (value.unit === "mV") {
    const electrode = paramName.split("_")[1]

    increment = 100
    if (paramName.endsWith("max_threshold")) {
      rangeMax = parseInt(config.params[`elec_${electrode}_maximum_input`].value, 16)
      if (coconTriggerIsSet) {
        rangeMin = parseInt(config.params[`elec_${electrode}_cocon_threshold`].value, 16)
      } else {
        rangeMin = parseInt(config.params[`elec_${electrode}_on_threshold`].value, 16)
      }
    } else if (paramName.endsWith("cocon_threshold")) {
      rangeMin = parseInt(config.params[`elec_${electrode}_on_threshold`].value, 16)
      rangeMax = parseInt(config.params[`elec_${electrode}_max_threshold`].value, 16)
    } else if (paramName.endsWith("maximum_input")) {
      rangeMin = parseInt(config.params[`elec_${electrode}_max_threshold`].value, 16)
      const maxVal = () => {
        const interfaceType = config.params.interface_type.display_value
        if (interfaceType === "Electrode") {
          return 5000
        } else if (interfaceType === "Force Sensitive Resistor") {
          return 3500
        } else if (interfaceType === "Linear Transducer") {
          return 8500
        } else return 5000
      }
      rangeMax = maxVal()
    } else if (paramName.endsWith("on_threshold")) {
      rangeMin = 400 // minimum on threshold
      if (coconTriggerIsSet) {
        rangeMax = parseInt(config.params[`elec_${electrode}_cocon_threshold`].value, 16)
      } else {
        rangeMax = parseInt(config.params[`elec_${electrode}_max_threshold`].value, 16)
      }
    }
  }

  const display = () => {
    if (["s", "min."].includes(value.unit) && val === 0) {
      return ls.getText("off_option_name")
    } else if (!coconTriggerIsSet && paramName.endsWith("cocon_threshold")) {
      return ls.getText("N/A")
    } else {
      return (
        (value.unit === "s" ? val / 1000 : val).toString() +
        value.unit.replace(".", val > 1 ? "s" : "")
      )
    }
  }

  const handleChange = ({ increase }: { increase: boolean }) => {
    let newVal = increase ? val + increment : val - increment
    if (newVal >= rangeMax) {
      setVal(rangeMax)
    } else if (newVal <= rangeMin) {
      setVal(rangeMin)
    } else {
      setVal(newVal)
    }
  }

  return val >= 0 ? (
    <div className="flex  sm:flex-row my-2 items-center space-y-2 sm:space-y-0 ">
      <div className="flex flex-1 items-center justify-self-start text-left w0">
        <p className="">{value.display_name}</p>
      </div>
      <div className="items-end justify-self-end">
        <ButtonGroup
          className={"h-[40px]"}
          variant="contained"
          aria-label="outlined primary button group"
          sx={{
            "& .MuiButtonGroup-grouped": {
              "&:not(:last-of-type)": {
                border: 0,
              },
              "&:not(:last-of-type).Mui-disabled": {
                border: 0,
              },
            },
          }}
          disableElevation
        >
          <Button
            onClick={() => handleChange({ increase: false })}
            disabled={
              val === rangeMin ||
              !canEdit ||
              (!coconTriggerIsSet && paramName.endsWith("cocon_threshold"))
            }
          >
            <RemoveIcon />
          </Button>
          <div
            className={`flex items-center border border-copper  ${
              val < rangeMin || val > rangeMax ? "bg-red-500" : "bg-white"
            }`}
          >
            <h1 className={`w-[70px] text-center`}>{display()}</h1>
          </div>
          <Button
            onClick={() => handleChange({ increase: true })}
            disabled={
              val === rangeMax ||
              !canEdit ||
              (!coconTriggerIsSet && paramName.endsWith("cocon_threshold"))
            }
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
      </div>
      {helperText && width && width > 640 && (
        <div className="ml-2 justify-self-end">
          <HelpTextTooltip text={helperText} />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default NumberInput
