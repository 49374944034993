import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  or,
  arrayRemove,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { firestore, functions } from "./firebase"

import { languageSelector as ls } from "@covvi/language-selector"
import { ProfileData } from "@typesFolder/types"

let covviUids: string[] = []

export const getMyUsers = (userId: string, role: string) => {
  return new Promise<ProfileData[]>((resolve, reject) =>
    (["Admin", "Customer Service Team Member", "Sales Team Member"].includes(role)
      ? getDocs(collection(firestore, "Users"))
      : getDocs(
          query(
            collection(firestore, "Users"),
            or(
              where("distributor", "==", userId),
              where("clinician", "==", userId),
              where("invited_by", "==", userId)
            )
          )
        )
    )
      .then((querySnapshot) => {
        let users: ProfileData[] = []
        querySnapshot.forEach((user) => {
          const userData = user.data()
          users.push({ ...userData, uid: user.id } as ProfileData)
        })
        resolve(users)
      })
      .catch(() => reject("Error retrieving users"))
  )
}

export const getCovviUids = () => {
  return covviUids
}
export const setCovviUids = () => {
  return new Promise<string[]>((resolve, reject) => {
    const getUids = httpsCallable(functions, "getCovviUids")
    getUids()
      .then((res) => {
        covviUids = res.data as string[]
        resolve(res.data as string[])
      })
      .catch(reject)
  })
}

export const inviteUser = (newUser: ProfileData, inviterProfile: ProfileData) => {
  const data = { ...newUser, invited_by: inviterProfile.uid }
  return new Promise((resolve, reject) => {
    if (
      ["Sales Team Member", "Customer Service Team Member", "Tech Team Member", "Admin"].includes(
        newUser.role
      ) &&
      !newUser.email_address.endsWith("@covvi.com")
    ) {
      reject(newUser.role + "s require a @covvi.com email address.")
    } else {
      const addUser = httpsCallable(functions, "addUser")
      addUser(data)
        .then((res) => {
          const newUserUID = res.data as string
          if (data.clinician || inviterProfile.role === "Clinician") {
            addAssociatedUser(
              inviterProfile.role === "Clinician" ? inviterProfile.uid : data.clinician!,
              newUserUID
            )
              .then(() => resolve(true))
              .catch((e) => reject("Error adding associated user"))
          } else if (data.distributor) {
            addAssociatedUser(data.distributor, newUserUID)
              .then(() => resolve(true))
              .catch((e) => reject("Error adding associated user"))
          } else {
            resolve(true)
          }
        })
        .catch((e) =>
          e?.code === "functions/already-exists"
            ? reject(ls.getText(e.message))
            : reject(ls.getText("There was an error creating this account"))
        )
    }
  })
}

const addAssociatedUser = (profileToUpdate: string, uidToAdd: string) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(firestore, "Users", profileToUpdate), {
      associated_users: arrayUnion(uidToAdd),
    })
      .then(resolve)
      .catch(reject)
  })
}

export const updateUser = async (userData: ProfileData) => {
  return new Promise(async (resolve, reject) => {
    if (
      ["Sales Team Member", "Customer Service Team Member", "Tech Team Member", "Admin"].includes(
        userData.role
      ) &&
      !userData.email_address.endsWith("@covvi.com")
    ) {
      reject(userData.role + "s require a @covvi.com email address.")
    } else {
      await setDoc(doc(firestore, "Users", userData.uid), userData, { merge: true })
        .then(async () => {
          if (userData.clinician || userData.distributor) {
            await addAssociatedUser(
              userData.clinician ? userData.clinician! : userData.distributor!,
              userData.uid
            )
              .then(resolve)
              .catch(reject)
          } else {
            resolve(true)
          }
        })
        .catch(() => reject("There was an error updating this account"))
    }
  })
}

export const deleteUser = (user: ProfileData) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(firestore, "Users", user.uid))
      .then(() => {
        const associatedAccount = user.clinician || user.distributor
        associatedAccount
          ? updateDoc(doc(firestore, "Users", associatedAccount), {
              associated_users: arrayRemove(user.uid),
            }).then(resolve)
          : resolve(true)
      })
      .catch((e) => console.log(e))
  })
}

export const requestAccountDeletion = (profile: ProfileData, reason: string) => {
  return new Promise((resolve, reject) => {
    const data = { profile, reason }
    const requestDeletion = httpsCallable(functions, "requestAccountDeletion")
    requestDeletion(data)
      .then(() => resolve(true))
      .catch(() => reject("There was an error sending your request"))
  })
}
