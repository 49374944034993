interface Props {
  array: {
    title: string
    value: string
  }[]
  whiteBackground?: boolean
}

const KeyValueArrayInfoCard = ({ array, whiteBackground }: Props) => {
  return (
    <div
      className={`rounded-[10px] border-[#B8B8B8] border flex flex-1 flex-col  sm:whitespace-nowrap overflow-x-auto ${
        whiteBackground ? "bg-white" : ""
      }`}
    >
      {array.map((info, i) => (
        <div
          key={i}
          className={`flex flex-row justify-between px-8 py-2 ${i % 2 ? "bg-gray-100" : ""}`}
        >
          <h3 className="font-semibold text-lg text-[#464646]">{info.title}</h3>
          <h4 className="text-base text-[#464646]">{info.value}</h4>
        </div>
      ))}
    </div>
  )
}

export default KeyValueArrayInfoCard
