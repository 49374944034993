import { languageSelector as ls } from "@covvi/language-selector"
import CVCardBody from "@ui/sections/CVCardBody"

export const NonAccreditationCard = () => {
  return (
    <CVCardBody roundedBottom={true} roundedTop={true}>
      <div className="mx-auto pt-8 lg:pt-[15%] px-8 text-xl">
        {ls.getText("non_accreditation_1")}
      </div>
      <div className="mx-auto pt-8 px-8 font text-xl">
        {ls.getText("non_accreditation_2")} customerservice@covvi.com
      </div>
    </CVCardBody>
  )
}
