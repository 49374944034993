import { useState } from "react"
import Graph from "@ui/graphs/Graph"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import CVCardBody from "@ui/sections/CVCardBody"
import { FilteredErrors } from "@typesFolder/types"

const ErrorsOverview = ({ filteredErrors }: { filteredErrors: FilteredErrors }) => {
  const [selected, setSelected] = useState<string>("Errors Overview")

  return (
    <>
      <div className="flex flex-col flex-1">
        <SecondaryTabArray
          buttonArray={Object.keys(filteredErrors).map((title) => {
            return { title }
          })}
          selected={selected}
          setSelected={setSelected}
          isWithinCard={false}
        />
        <div className="w-full mt-4 flex flex-col flex-1">
          <CVCardBody roundedTop={true} roundedBottom={true}>
            <div className="flex flex-col flex-1">
              <Graph
                graphData={{
                  title: selected,
                  data: Object.entries(filteredErrors).filter(
                    (entry) => entry[0] === selected
                  )[0][1],
                }}
                pageTitle={selected}
                isLogarithmic={true}
              />
            </div>
          </CVCardBody>
        </div>
      </div>
    </>
  )
}

export default ErrorsOverview
