interface Props {
  isGray?: boolean
  children?: React.ReactNode
  embedded?: boolean
}

const CVCardHeader = ({ isGray, embedded = false, children }: Props) => {
  return (
    <div
      className={`bg-${isGray ? "gray-100" : "white"} min-h-[76px] w-full ${
        !embedded && "rounded-t-xl"
      } flex flex-row m-auto justify-between items-center border border-trueGray-400 overflow-auto lg:overflow-hidden`}
    >
      {children}
    </div>
  )
}

export default CVCardHeader
