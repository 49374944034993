import { languageSelector as ls } from "@covvi/language-selector"
import BasicModal from "@ui/modals/BasicModal"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"

export const AccreditationModal = ({
  showingModal,
  setShowingModal,
  confirm,
}: {
  showingModal: boolean
  setShowingModal: Function
  confirm: () => void
}) => {
  return (
    <BasicModal open={showingModal} setClosed={() => setShowingModal(false)}>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("set_accredit")} />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="py-12 px-10">{ls.getText("accredit_body")}</div>
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={[
            {
              title: ls.getText("accredit_title"),
              color: "error",
              variant: "contained",
              onClick: confirm,
            },
            {
              variant: "outlined",
              onClick: () => setShowingModal(false),
              title: ls.getText("Cancel"),
            },
          ]}
        />
      </CVCardFooter>
    </BasicModal>
  )
}
