import { useState } from "react"
import { TextField } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useCurrentHandContext, useCurrentSessionContext } from "@context/remoteAssistProviders"
import { ErrorObj } from "@typesFolder/types"

import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"

interface Props {
  setShowingQuestionnaire: Function
  canEdit: boolean
}

const QuestionnaireModal = ({ setShowingQuestionnaire, canEdit }: Props) => {
  const { questionnaire } = useCurrentSessionContext()
  const { emitData } = useCurrentHandContext()
  const [tempQuestionnaire, setTempQuestionnaire] = useState(questionnaire)
  const [errors, setErrors] = useState({})

  const validateForm = () => {
    let formErrors: ErrorObj = {}

    for (let question in tempQuestionnaire) {
      if (tempQuestionnaire[question].answer === "") {
        formErrors[question] = true
      }
    }
    let isError = false
    for (const prop in formErrors) {
      if (formErrors[prop as keyof typeof formErrors] === true) {
        isError = true
      }
    }
    setErrors({ ...formErrors })

    return !isError
  }

  const handleSubmit = () => {
    let isValid = validateForm()
    if (isValid === false) {
    } else {
      tempQuestionnaire && emitData({ type: "write_questionnaire", data: tempQuestionnaire })
      setShowingQuestionnaire(false)
    }
  }

  const updateField = (question: string, answer: string) => {
    setTempQuestionnaire({
      ...tempQuestionnaire,
      [question]: { ...tempQuestionnaire![question], answer },
    })
  }

  return (
    <>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Questionnaire")} />
        {!canEdit && <CVCloseModal setShowingModal={setShowingQuestionnaire} />}
      </CVCardHeader>
      <CVCardBody>
        <div
          className={`flex flex-col py-[25px] px-[30px] space-y-[30px]
          `}
        >
          {tempQuestionnaire &&
            Object.keys(tempQuestionnaire!).map((question, index) => {
              return (
                <TextField
                  key={index}
                  error={errors[question as keyof typeof errors]}
                  helperText={
                    errors[question as keyof typeof errors]
                      ? ls.getText("Please fill in this field")
                      : ""
                  }
                  value={tempQuestionnaire![question].answer}
                  label={tempQuestionnaire![question].question}
                  onChange={(e) => {
                    updateField(question, e.currentTarget.value)
                  }}
                  required
                  multiline={true}
                  minRows={3}
                  fullWidth
                  disabled={!canEdit}
                />
              )
            })}
        </div>
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={[
            {
              variant: "contained",
              onClick: canEdit ? handleSubmit : setShowingQuestionnaire(false),
              title: ls.getText(canEdit ? "Submit" : "Close"),
            },
          ]}
        />
      </CVCardFooter>
    </>
  )
}

export default QuestionnaireModal
