import { languageSelector as ls } from "@covvi/language-selector"

const ErrorPage = () => {
  return (
    <div className={"flex flex-col justify-between text-center"}>
      <h1 className={"text-white font-bold text-2xl sm:text-4xl mt-4 mb-1 sm:mt-12 sm:mb-6"}>
        {"404 " + ls.getText("Page not found")}
      </h1>
      <div className="hidden sm:flex">
        <img
          className={``}
          alt={"Desktop 404"}
          src={require(`./../../assets/images/404-Desktop.png`)}
        />
      </div>
      <div className="flex sm:hidden">
        <img
          className={``}
          alt={"Mobile 404"}
          src={require(`./../../assets/images/404-Mobile.png`)}
        />
      </div>
    </div>
  )
}

export default ErrorPage
