import React from "react"
import Dialog from "@mui/material/Dialog"

const BasicModal = ({
  open,
  setClosed,
  children,
  modalTitle = "Modal-title",
  modalDescription = "Modal-Description",
  fullWidth = false,
}: {
  open: boolean
  setClosed: () => void
  children: React.ReactNode
  modalTitle?: string
  modalDescription?: string
  fullWidth?: boolean
}) => {
  return (
    <Dialog
      open={open}
      onClose={setClosed}
      aria-labelledby={modalTitle}
      aria-describedby={modalDescription}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          borderRadius: "10px",
        },
      }}
    >
      {children}
    </Dialog>
  )
}
export default BasicModal
