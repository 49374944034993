import { useEffect, useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { getStatisticsSchema } from "@covvi/common-functions"
import { getGeneralStats } from "@util/firebase/handFunctions"
import { useAuth } from "@context/AuthContext"
import { getMedian } from "@util/commonFunctions/getMedian"
import StatsOverview from "@components/sections/StatsOverview"
import AllGeneralOptions from "@components/sections/AllGeneralOptions"
import KeyValueArrayInfoCard from "@ui/cards/KeyValueArrayInfoCard"
import Spinner from "@ui/spinners/Spinner"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import { HeaderCard } from "@ui/cards/HeaderCard"
import { ClearCard } from "@ui/cards/ClearCard"
import { GeneralGraphOptions } from "@typesFolder/types"
import { GeneralStatsObj } from "@typesFolder/statsTypes"

interface Props {
  pillArray: {
    buttons: { title: string; onClick: () => void }[]
    selected: string
    setSelected: Function
  }
}

interface DataObj {
  title: string
  data: { [key: string]: number }
}

export const AllStatsOverview = ({ pillArray }: Props) => {
  const { profile } = useAuth()
  const [allStats, setAllStats] = useState<GeneralStatsObj[]>()
  const [sampleSize, setSampleSize] = useState<number>(0)
  const [gripUsageData, setGripUsageData] = useState<DataObj>()
  const [gripCloseData, setGripCloseData] = useState<DataObj>()
  const [triggerData, setTriggerData] = useState<DataObj>()
  const [limitData, setLimitData] = useState<DataObj>()
  const [counterData, setCounterData] = useState<DataObj>()
  const [graphOptions, setGraphOptions] = useState<GeneralGraphOptions>({
    showingRandD: false,
    showingLoaner: true,
    showingDemo: true,
    showingCustomer: true,
    showingLeft: true,
    showingRight: true,
    showingSmall: true,
    showingMedium: true,
    showingLarge: true,
    usage: [10, 1000000],
    connections: [1, 1000000],
    showingPassiveUsage: false,
    showingMedian: true,
    timePeriod: 2630000000,
    snapshotTimeDifference: 86400000,
  })

  const infoCard = [
    { title: ls.getText("hand_sample_size"), value: sampleSize.toString() },
    {
      title: ls.getText("average_grips_cycled"),
      value: gripCloseData
        ? (
            Math.round(
              Object.entries(gripCloseData.data).reduce((accumulator, currentValue) => {
                return !["powered_on", "idle", "standby"].includes(currentValue[0])
                  ? accumulator + currentValue[1]
                  : accumulator
              }, 0) * 10
            ) / 10
          ).toString()
        : "",
    },
    {
      title: ls.getText("average_triggers_performed"),
      value: triggerData
        ? Math.round(
            Object.values(triggerData.data).reduce((accumulator, currentValue) => {
              return accumulator + (currentValue | 0)
            }, 0)
          ).toString()
        : "",
    },
  ]

  useEffect(() => {
    getGeneralStats(profile!).then(setAllStats)
  }, [])

  useEffect(() => {
    if (!allStats) {
      return
    }
    const medianStats: {
      data_counter: { [key: string]: number[] }
      data_triggers: { [key: string]: number[] }
      data_limits: { [key: string]: number[] }
      data_grips: { [key: string]: number[] }
      data_usage: { [key: string]: number[] }
    } = {
      data_counter: {},
      data_triggers: {},
      data_limits: {},
      data_grips: {},
      data_usage: {},
    }
    const averageStats: { [key: string]: { [key: string]: number } } = {
      data_counter: {},
      data_triggers: {},
      data_limits: {},
      data_grips: {},
      data_usage: {},
    }

    let statsToEdit = [...allStats]

    statsToEdit = statsToEdit.filter(
      (hand) =>
        hand.entries > graphOptions.connections[0] && hand.entries < graphOptions.connections[1]
    )
    statsToEdit = statsToEdit.filter(
      (hand) =>
        hand.handMaxStats.data_usage.powered_on > graphOptions.usage[0] * 3600 &&
        hand.handMaxStats.data_usage.powered_on < graphOptions.usage[1] * 3600
    )

    if (graphOptions.snapshotTimeDifference !== "any")
      statsToEdit = statsToEdit.filter(
        (hands) =>
          typeof graphOptions.snapshotTimeDifference === "number" &&
          hands.timeDifference > graphOptions.snapshotTimeDifference
      )

    if (!graphOptions.showingLeft)
      statsToEdit = statsToEdit.filter((hand) => !(hand.handId.charAt(3) === "L"))
    if (!graphOptions.showingRight)
      statsToEdit = statsToEdit.filter((hand) => !(hand.handId.charAt(3) === "R"))
    if (!graphOptions.showingSmall)
      statsToEdit = statsToEdit.filter((hand) => !(hand.handId.charAt(4) === "S"))
    if (!graphOptions.showingMedium)
      statsToEdit = statsToEdit.filter((hand) => !(hand.handId.charAt(4) === "M"))
    if (!graphOptions.showingLarge)
      statsToEdit = statsToEdit.filter((hand) => !(hand.handId.charAt(4) === "L"))
    if (!graphOptions.showingRandD)
      statsToEdit = statsToEdit.filter((hand) => !hand.handId.includes("RD1"))
    if (!graphOptions.showingLoaner)
      statsToEdit = statsToEdit.filter((hand) => !hand.handId.includes("LN1"))
    if (!graphOptions.showingDemo)
      statsToEdit = statsToEdit.filter((hand) => !hand.handId.includes("DH1"))
    if (!graphOptions.showingCustomer)
      statsToEdit = statsToEdit.filter((hand) => !hand.handId.includes("CV1"))

    statsToEdit.forEach(({ handMaxStats, firstHandStats, timeDifference }, i) => {
      Object.entries({
        ...getStatisticsSchema(105),
        ...getStatisticsSchema(106),
        ...getStatisticsSchema(107),
      }).forEach(([title, section]) => {
          ;("sub_options" in section.options[0]
            ? section.options[0].sub_options
            : section.options
          ).forEach((stat) => {
            if ("title" in stat && handMaxStats[title]) {
              let thisVal
              if (graphOptions.timePeriod !== "allTime" && title !== "data_limits") {
                thisVal = Math.round(
                  ((handMaxStats[title][stat.title] - firstHandStats[title][stat.title]) /
                    timeDifference) *
                    graphOptions.timePeriod
                )
              } else {
                thisVal = handMaxStats[title][stat.title]
              }
              if (graphOptions.showingMedian) {
                let command = title as
                  | "data_usage"
                | "data_grips"
                  | "data_triggers"
                  | "data_counter"
                  | "data_limits"
                if (!medianStats[command][stat.title]) {
                  medianStats[command][stat.title] = [thisVal]
                } else {
                  medianStats[command][stat.title].push(thisVal)
                }
                if (i === statsToEdit.length - 1) {
                  averageStats[title][stat.title] = getMedian(medianStats[command][stat.title])
                }
              } else {
                if (!averageStats[title][stat.title]) {
                  averageStats[title][stat.title] = thisVal
                } else {
                  averageStats[title][stat.title] = averageStats[title][stat.title] += thisVal
                }
                if (statsToEdit.length > 1 && i === statsToEdit.length - 1) {
                  averageStats[title][stat.title] = parseFloat(
                    (averageStats[title][stat.title] / statsToEdit.length).toFixed(2)
                  )
                }
              }
            }
          })
        }
      )
    })

    !graphOptions.showingPassiveUsage &&
      delete averageStats.data_usage.powered_on &&
      delete averageStats.data_usage.idle &&
      delete averageStats.data_usage.standby
    setSampleSize(statsToEdit.length)
    setGripUsageData({ title: "Data Usage", data: averageStats.data_usage })
    setGripCloseData({ title: "Data Grips", data: averageStats.data_grips })
    setTriggerData({ title: "Data Triggers", data: averageStats.data_triggers })
    setLimitData({ title: "Data Limits", data: averageStats.data_limits })
    setCounterData({ title: "Data Counter", data: averageStats.data_counter })
  }, [allStats, graphOptions])

  return (
    <ClearCard>
      <div className="pb-5">
        <HeaderCard>
          <div className="flex-1 space-y-[20px]">
            <PillButtonArray
              pillArray={pillArray.buttons}
              selected={pillArray.selected}
              setSelected={pillArray.setSelected}
            />
            <KeyValueArrayInfoCard array={infoCard} whiteBackground={true} />
          </div>
          <AllGeneralOptions
            graphOptions={graphOptions}
            setGraphOptions={setGraphOptions}
            optionType="Stats"
          />
        </HeaderCard>
        {gripUsageData && gripCloseData && counterData && limitData && triggerData ? (
          <StatsOverview
            dataUsage={gripUsageData}
            dataGrips={gripCloseData}
            dataCounter={counterData}
            dataLimits={limitData}
            dataTriggers={triggerData}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </ClearCard>
  )
}
