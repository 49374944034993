import { SupportMessage } from "@typesFolder/supportTypes"

interface Props {
  message: SupportMessage
}

const CVMessageCard = ({ message }: Props) => {
  const fromCovvi = message.from_email.endsWith("@covvi.com")

  return (
    <div
      className={`rounded-xl
      ${fromCovvi ? "bg-copperFade" : "bg-gray-50"}
      py-[20px] px-[30px] w-10/12 min-h-[95px] my-[8px] m-auto break-words`}
    >
      <div className="flex flex-row justify-between text-xs mb-[18px]">
        <p className="text-gray-500">{message.from_email}</p>
        <p className="text-gray-500">{new Date(message.date.seconds * 1000).toLocaleString()}</p>
      </div>
      {message.content.map((content, index) => {
        return content && content !== ">" && <p key={index}>{content}</p>
      })}
    </div>
  )
}

export default CVMessageCard
