import { Fragment } from "react"
import { useAuth } from "@context/AuthContext"
import { useNavigate } from "react-router-dom"
import { ArrowRightOnRectangleIcon, CubeIcon } from "@heroicons/react/24/outline"
import {
  HandRaisedIcon,
  UsersIcon,
  ComputerDesktopIcon,
  TicketIcon,
  UserCircleIcon,
  LanguageIcon,
} from "@heroicons/react/24/solid"
import { Tooltip, Zoom } from "@mui/material"

import { NavType } from "@typesFolder/types"
import NavigationStructure from "../../../assets/Navigation.json"
import covviWhite from "./../../../assets/images/COVVI-Delta-White.svg"

export const SideNav = ({ title }: { title: string }) => {
  const navigation: NavType = NavigationStructure
  const { profile } = useAuth()
  const navigate = useNavigate()

  const Icon = ({ section, styling }: { section: string; styling: string }) => {
    switch (section) {
      case "Account Management":
        return <UserCircleIcon className={styling} />
      case "Users":
        return <UsersIcon className={styling} />
      case "Hands":
        return <HandRaisedIcon className={styling} />
      case "Support Tickets":
        return <TicketIcon className={styling} />
      case "Remote Assist":
        return <ComputerDesktopIcon className={styling} />
      case "Returns":
        return <CubeIcon className={styling} />
      case "Translations":
        return <LanguageIcon className={styling} />
      case "Sign Out":
        return <ArrowRightOnRectangleIcon className={styling} />
      default:
        return <></>
    }
  }

  return (
    <aside className="hidden lg:flex lg:w-32 px-2 rounded-l-3xl bg-black min-w-[120px]">
      <div className="sticky top-0 px-4 w-full overflow-auto">
        <div className={"group flex items-center text-base rounded-xl my-8 min-h-[110px]"}>
          <img className={"mx-auto my-4 px-2"} src={covviWhite} alt="Covvi Logo" />
        </div>
        {Object.keys(navigation)
          .filter((a) => {
            return navigation[a].userLevel.includes(profile!.role)
          })
          .map((item, key) => (
            <Fragment key={key}>
              <Tooltip title={item} arrow TransitionComponent={Zoom} enterDelay={1000}>
                <div
                  key={item}
                  onClick={() => navigate(`${navigation[item].href}`)}
                  className={`
                    group flex items-center text-base rounded-xl my-8 transition-color duration-300 betterhover:hover:bg-copper betterhover:hover:cursor-pointer ${
                      title === item
                        ? "bg-copper "
                        : "text-lightCovviGrey betterhover:hover:text-white"
                    }`}
                >
                  <Icon
                    section={item}
                    styling={`${
                      title === item ? "bg-copper text-white" : ""
                    } flex-shrink-0 h-8 w-8 my-4 mx-auto`}
                  />
                </div>
              </Tooltip>
            </Fragment>
          ))}
      </div>
    </aside>
  )
}
