import CircularProgress from "@mui/material/CircularProgress"
import Button, { ButtonProps } from "@mui/material/Button"
import { SxProps } from "@mui/material"

const ButtonWithLoading = ({
  title,
  loading,
  onClick,
  variant = "contained",
  color = "primary",
  disabled = false,
  styleSXOverrides = {},
}: {
  title: string
  loading: boolean
  onClick: () => void
  variant?: ButtonProps["variant"]
  color?: ButtonProps["color"]
  disabled?: boolean
  styleSXOverrides?: SxProps
}) => {
  return (
    <div className="m-[1px] relative">
      <Button
        variant={variant}
        disabled={loading || disabled}
        onClick={onClick}
        color={color}
        disableElevation
        sx={styleSXOverrides}
      >
        {title}
      </Button>
      {loading && (
        <CircularProgress
          color={"primary"}
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </div>
  )
}

export default ButtonWithLoading
