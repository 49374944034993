import { SyntheticEvent, useEffect, useState } from "react"
import { Slider } from "@mui/material"
import Spinner from "@ui/spinners/Spinner"

interface Props {
  title: string
  values: [number, number]
  setValues: (values: [number, number]) => void
}

export const NonLinearSlider = ({ title, values, setValues }: Props) => {
  const [minMax, setMinMax] = useState<[number, number]>()
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth(),
    d = date.getDate()
  const [marks, setMarks] = useState<
    {
      value: number
      scaledValue: number
      label: string
    }[]
  >()

  useEffect(() => {
    switch (title) {
      case "usage":
        setMinMax([
          usageMarks.filter((mark) => mark.scaledValue === values[0])[0].value,
          usageMarks.filter((mark) => mark.scaledValue === values[1])[0].value,
        ])
        return setMarks(usageMarks)
      case "connections":
        setMinMax([
          connectionMarks.filter((mark) => mark.scaledValue === values[0])[0].value,
          connectionMarks.filter((mark) => mark.scaledValue === values[1])[0].value,
        ])
        return setMarks(connectionMarks)
      case "by_date":
        setMinMax([
          dateMarks.filter((mark) => mark.scaledValue === values[0])[0].value,
          dateMarks.filter((mark) => mark.scaledValue === values[1])[0].value,
        ])
        return setMarks(dateMarks)
      default:
        break
    }
  }, [title, values])

  const formattedDate = (epoch: number) => {
    return new Date(epoch).toLocaleDateString("en-gb", {
      month: "short",
    })
  }

  const historicMonth = (monthsToRemove: number) => {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth()
    var firstDay = new Date(y, m, 1)
    firstDay.setDate(1)
    firstDay.setMonth(firstDay.getMonth() - monthsToRemove)
    return firstDay.getTime().valueOf()
  }

  const usageMarks = [
    {
      value: 0,
      scaledValue: 0,
      label: "0",
    },
    {
      value: 25,
      scaledValue: 10,
      label: "10",
    },
    {
      value: 50,
      scaledValue: 20,
      label: "20",
    },
    {
      value: 75,
      scaledValue: 50,
      label: "50",
    },
    {
      value: 100,
      scaledValue: 100,
      label: "100",
    },
    {
      value: 125,
      scaledValue: 250,
      label: "250",
    },
    {
      value: 150,
      scaledValue: 500,
      label: "500",
    },
    {
      value: 175,
      scaledValue: 1000,
      label: "1000",
    },
    {
      value: 200,
      scaledValue: 1000000,
      label: "1M",
    },
  ]

  const connectionMarks = [
    {
      value: 0,
      scaledValue: 1,
      label: "1",
    },
    {
      value: 25,
      scaledValue: 5,
      label: "5",
    },
    {
      value: 50,
      scaledValue: 10,
      label: "10",
    },
    {
      value: 75,
      scaledValue: 50,
      label: "50",
    },
    {
      value: 100,
      scaledValue: 100,
      label: "100",
    },
    {
      value: 125,
      scaledValue: 1000000,
      label: "1M",
    },
  ]

  const dateMarks = [
    {
      value: 0,
      scaledValue: 0,
      label: "Any",
    },
    {
      value: 25,
      scaledValue: historicMonth(6),
      label: formattedDate(historicMonth(6)),
    },
    {
      value: 50,
      scaledValue: historicMonth(3),
      label: formattedDate(historicMonth(3)),
    },
    {
      value: 75,
      scaledValue: historicMonth(2),
      label: formattedDate(historicMonth(2)),
    },
    {
      value: 100,
      scaledValue: historicMonth(1),
      label: formattedDate(historicMonth(1)),
    },
    {
      value: 125,
      scaledValue: historicMonth(0),
      label: formattedDate(historicMonth(0)),
    },
    {
      value: 150,
      scaledValue: new Date(y, m, d + 1).getTime().valueOf(),
      label: "Latest",
    },
  ]

  return minMax && marks ? (
    <Slider
      style={{ maxWidth: 600 }}
      value={minMax}
      min={0}
      step={25}
      max={marks ? marks[marks?.length - 1].value : 0}
      marks={marks}
      onChange={(event: Event, newValue: number[] | number) =>
        Array.isArray(newValue) && setMinMax([newValue[0], newValue[1]])
      }
      onChangeCommitted={(
        event: Event | SyntheticEvent<Element, Event>,
        newValue: number[] | number
      ) => {
        if (Array.isArray(newValue)) {
          setValues([
            marks.filter((mark) => mark.value === newValue[0])[0].scaledValue,
            marks.filter((mark) => mark.value === newValue[1])[0].scaledValue,
          ])
        }
      }}
      aria-labelledby="non-linear-slider"
    />
  ) : (
    <Spinner />
  )
}
