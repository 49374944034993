import { useEffect, useState } from "react"

import { useAuth } from "@context/AuthContext"
import { useNavigate } from "react-router-dom"
import { getListOfHands, getMyUsersHands } from "@util/firebase/handFunctions"
import extractHandInfo from "../../util/commonFunctions/extractHandInfo"
import { DefaultObject, NewTableOptions, TableData } from "@typesFolder/types"

import Table from "@ui/table/Table"
import { setCovviUids } from "@util/firebase/userFunctions"
import { AllStatsOverview } from "@pages/hands/AllStatsOverview"
import { AllErrorsOverview } from "./AllErrorsOverview"
import useWindowSize from "@util/hooks/useWindowSize"

const Hands = () => {
  const { profile } = useAuth()
  const { width = 1024 } = useWindowSize()
  const [hands, setHands] = useState<TableData[]>([])
  const [tabSelectValue, setTabSelectValue] = useState<"All Hands" | "Statistics" | "Errors">(
    "All Hands"
  )
  const navigate = useNavigate()

  const openHand = (data: DefaultObject): void => {
    navigate(`/hands/${data.id}`)
  }

  useEffect(() => {
    if (profile) {
      setCovviUids()
      if (
        ["Sales Team Member", "Customer Service Team Member", "Tech Team Member", "Admin"].includes(
          profile.role
        )
      ) {
        getListOfHands(profile)
          .then((res) => {
            let handArray: string[] = res as string[]
            const handsObjArr = handArray.map((hand: string) => {
              return extractHandInfo(hand)
            })

            setHands(handsObjArr)
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (profile.role === "Clinician") {
        getMyUsersHands(profile)
          .then((res) => {
            setHands(
              res.map((hand: string) => {
                return extractHandInfo(hand)
              })
            )
          })
          .catch((e) => {
            profile?.role === "Admin" && console.log(e)
          })
      } else if (profile.role === "User") {
        if (profile.associated_hands) {
          const handObjArr = profile.associated_hands.map((hand: string) => {
            return extractHandInfo(hand)
          })
          setHands(handObjArr)
        }
      }
    }
  }, [profile])

  const adminOptions = {
    buttons: [
      {
        title: "All Hands",
        onClick: () => setTabSelectValue("All Hands"),
      },
      {
        title: "Statistics",
        onClick: () => setTabSelectValue("Statistics"),
      },
      {
        title: "Errors",
        onClick: () => setTabSelectValue("Errors"),
      },
    ],
    selected: tabSelectValue,
    setSelected: () => setTabSelectValue,
  }

  const tableOptions: NewTableOptions = {
    fieldIds: ["id", ...(width > 640 ? ["laterality", "size", "color"] : [])],
    fieldTitles: ["Serial Number", ...(width > 640 ? ["laterality", "size", "color"] : [])],
    onClick: openHand,
    searchParams: ["id", ...(width > 640 ? ["size", "color"] : [])],
    ...(["Admin"].includes(profile!.role) && {
      pillArray: adminOptions,
    }),
  }

  switch (tabSelectValue) {
    case "Statistics":
      return <AllStatsOverview pillArray={adminOptions} />
    case "Errors":
      return <AllErrorsOverview pillArray={adminOptions} />
    default:
      return <Table data={hands} tableOptions={tableOptions} />
  }
}

export default Hands
