import { Hand } from "@typesFolder/types"

const extractHandInfo = (handId: string) => {
  let handObj: Hand = { id: handId }

  if (handObj.id[3] === "L") {
    handObj.laterality = "Left"
  } else if (handObj.id[3] === "R") {
    handObj.laterality = "Right"
  }

  if (handObj.id[4] === "S") {
    handObj.size = "Small"
  } else if (handObj.id[4] === "M") {
    handObj.size = "Medium"
  } else if (handObj.id[4] === "L") {
    handObj.size = "Large"
  }

  if (handObj.id[8] + handObj.id[9] === "CA") {
    handObj.color = "Carbon"
  }
  if (handObj.id[8] + handObj.id[9] === "WH") {
    handObj.color = "White"
  }
  if (handObj.id[8] + handObj.id[9] === "TG") {
    handObj.color = "Titan Grey"
  }
  if (handObj.id[8] + handObj.id[9] === "RG") {
    handObj.color = "Rose Gold"
  }

  return handObj
}

export default extractHandInfo
