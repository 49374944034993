import { PillButton } from "@ui/buttons/PillButton"

export const PillButtonArray = ({
  pillArray,
  selected,
  setSelected,
  extraMarginBottom,
}: {
  pillArray: { title: string; onClick: () => void }[]
  selected: string
  setSelected: Function
  extraMarginBottom?: boolean
}) => {
  return (
    <div
      className={`grid gap-1 grid-cols-2 md:flex md:flex-row md:flex-wrap md:gap-x-[10px] md:gap-y-[15px] w-full md:justify-start
       ${extraMarginBottom ? "mb-4 sm:mb-8" : ""}`}
    >
      {pillArray.map((button, i) => (
        <div key={i} className="mr-0 lg:mr-4">
          <PillButton
            title={button.title}
            onClick={() => {
              button.onClick()
              setSelected(button.title)
            }}
            active={selected === button.title}
          />
        </div>
      ))}
    </div>
  )
}
