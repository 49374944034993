import { Close } from "@mui/icons-material"

interface Props {
  setShowingModal: Function
}

const CVCloseModal = ({ setShowingModal }: Props) => {
  return (
    <div
      className="flex h-6 w-6 space-x-0 hover:bg-black/5 mr-[30px] lg:my-[25px] lg:mx-[30px]"
      onClick={() => setShowingModal(null)}
    >
      <Close fill={"#888888"} />
    </div>
  )
}

export default CVCloseModal
