import { getBlob, getStorage, ref } from "firebase/storage"
import { firestore } from "./firebase"
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore"
import { FeedbackResponse } from "@typesFolder/types"

export const acceptDataConsent = async (uid: string) => {
  return new Promise((resolve, reject) => {
    const userRef = doc(firestore, "Users", uid)
    updateDoc(userRef, {
      dataConsent: new Date(),
    })
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getFeedbackQuestionnaire = () => {
  return new Promise((resolve, reject) => {
    const storage = getStorage()
    // const pathReference = ref(storage, 'images/feedback_questionnaire.jpg');
    getBlob(ref(storage, "feedback_questionnaire.json"))
      .then(async (blob) => await blob.text())
      .then(async (json) => await JSON.parse(json))
      .then(resolve)
      .catch(() => reject("NONE"))
  })
}

export const uploadFeedbackResponse = ({ feedback }: { feedback: FeedbackResponse }) => {
  return new Promise((resolve, reject) => {
    addDoc(collection(firestore, "Feedback"), feedback)
      .then(() => resolve("OK"))
      .catch(() => reject("There was an error updating this account"))
  })
}
