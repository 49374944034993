import { useEffect, useState } from "react"
import { TextField, MenuItem } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { Config } from "@util/parser/Parser"
import { Row } from "@typesFolder/types"

import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import { UserGrips } from "@typesFolder/remoteAssistTypes"

const GripTables = ({
  config,
  content,
  updateParam,
  canEdit,
  userGrips,
}: {
  config: Config
  content: Row[]
  updateParam: Function
  canEdit: boolean
  userGrips: UserGrips | undefined
}) => {
  const [selected, setSelected] = useState("a")
  const tables = ["a", "b", "c", "d"]
  const [gripTable, setGripTable] = useState<Row[]>([])

  useEffect(
    () => content && setGripTable(content.filter((row) => row.name.includes(`table_${selected}`))),
    [content, selected]
  )

  return (
    <div className="flex flex-col justify-between py-2">
      <TextField
        className="basis-full"
        variant="outlined"
        label={ls.getText("Select Grip Table")}
        value={selected}
        select
        onChange={(e) => setSelected(e.target.value)}
        required
        disabled={false}
      >
        {tables.map((table, i) => (
          <MenuItem key={i} value={table}>
            {ls.getText(`table_${table}_name`)}
          </MenuItem>
        ))}
      </TextField>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-10 pt-10 ">
        {gripTable.map((row, i) => (
          <SelectInput
            key={i}
            value={config.params[row.name]}
            setValue={updateParam}
            canEdit={canEdit}
            userGrips={userGrips}
          />
        ))}
      </div>
    </div>
  )
}

export default GripTables
