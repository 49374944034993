import { useEffect, useState } from "react"

import { useCurrentHandContext } from "@context/remoteAssistProviders"
import { toHex, toInt } from "@covvi/common-functions"
import { Config } from "@util/parser/Parser"
import { languageSelector as ls } from "@covvi/language-selector"

import InputLevelBar from "@components/input graph/InputLevelBar"
import ThresholdBar from "@components/input graph/ThresholdBar"
import CVSubtitle from "@ui/text/CVSubtitle"

interface Props {
  handConfig: Config
  disableElectrodeHandles: boolean
}

const ElectrodeData = ({ handConfig, disableElectrodeHandles }: Props) => {
  const { elecA, elecB, emitData } = useCurrentHandContext()
  const [onA, setOnA] = useState<number>(0)
  const [coconA, setCoconA] = useState<number>(0)
  const [maxA, setMaxA] = useState<number>(5000)
  const [onB, setOnB] = useState<number>(0)
  const [coconB, setCoconB] = useState<number>(0)
  const [maxB, setMaxB] = useState<number>(5000)
  const [coconActive, setCoconActive] = useState(false)

  useEffect(() => {
    setOnA(toInt(handConfig.params["elec_a_on_threshold"].value))
    setCoconA(toInt(handConfig.params["elec_a_cocon_threshold"].value))
    setMaxA(toInt(handConfig.params["elec_a_max_threshold"].value))
    setOnB(toInt(handConfig.params["elec_b_on_threshold"].value))
    setCoconB(toInt(handConfig.params["elec_b_cocon_threshold"].value))
    setMaxB(toInt(handConfig.params["elec_b_max_threshold"].value))
    setCoconActive(handConfig.getValue("single_co_contraction") !== "00")
  }, [handConfig])

  const onMove = (values: number | number[], input: string) => {
    if (typeof values === "number" || !handConfig) return
    if (input === "A") {
      setOnA(values[0])
      coconActive && setCoconA(values[1])
      setMaxA(values[coconActive ? 2 : 1])
    } else if (input === "B") {
      setOnB(values[0])
      coconActive && setCoconB(values[1])
      setMaxB(values[coconActive ? 2 : 1])
    }
  }

  const onRelease = async (values: number | number[], input: string) => {
    if (typeof values === "number" || !handConfig) return
    emitData({ type: "streamStop", data: "electrode" })
    if (input === "A") {
      if (onA !== values[0]) {
        handConfig.updateParamValue("elec_a_on_threshold", toHex(values[0], 2))
      }
      if (!coconActive) {
        if (toInt(handConfig.getValue("elec_a_cocon_threshold")) < values[0]) {
          handConfig.updateParamValue("elec_a_cocon_threshold", toHex(values[0], 2))
        }
      } else if (coconActive && coconA !== values[1]) {
        const cocon = values[1] > values[0] ? values[1] : values[0]
        handConfig.updateParamValue("elec_a_cocon_threshold", toHex(cocon, 2))
      } else if (!coconActive && maxA !== values[1]) {
        handConfig.updateParamValue("elec_a_max_threshold", toHex(values[1], 2))
        if (toInt(handConfig.getValue("elec_a_cocon_threshold")) > values[1]) {
          handConfig.updateParamValue("elec_a_cocon_threshold", toHex(values[1], 2))
        }
      } else if (coconActive && maxA !== values[2]) {
        handConfig.updateParamValue("elec_a_max_threshold", toHex(values[2], 2))
      }
    } else if (input === "B") {
      if (onB !== values[0]) {
        handConfig.updateParamValue("elec_b_on_threshold", toHex(values[0], 2))
      }
      if (!coconActive) {
        if (toInt(handConfig.getValue("elec_b_cocon_threshold")) < values[0]) {
          handConfig.updateParamValue("elec_b_cocon_threshold", toHex(values[0], 2))
        }
      } else if (coconActive && coconB !== values[1]) {
        const cocon = values[1] > values[0] ? values[1] : values[0]
        handConfig.updateParamValue("elec_b_cocon_threshold", toHex(cocon, 2))
      } else if (!coconActive && maxB !== values[1]) {
        handConfig.updateParamValue("elec_b_max_threshold", toHex(values[1], 2))
        if (toInt(handConfig.getValue("elec_b_cocon_threshold")) > values[1]) {
          handConfig.updateParamValue("elec_b_cocon_threshold", toHex(values[1], 2))
        }
      } else if (coconActive && maxB !== values[2]) {
        handConfig.updateParamValue("elec_b_max_threshold", toHex(values[2], 2))
      }
    }

    Object.keys(handConfig.changes).length &&
      emitData({ type: "config", data: handConfig.hexValues })
    setTimeout(() => {
      emitData({ type: "streamStart", data: "electrode" })
    })
  }

  return (
    <div className="flex flex-col p-8">
      <CVSubtitle
        text={`${ls.getText("electrode_name")} A`}
        helperText={ls.getText("electrode_data_help")}
      />
      <ThresholdBar
        onVal={onA}
        coconVal={coconActive && coconA}
        maxVal={maxA}
        maxInput={toInt(handConfig.params["elec_a_maximum_input"].value)}
        disabled={disableElectrodeHandles}
        onMove={(...args) => onMove(...args, "A")}
        onRelease={(...args) => onRelease(...args, "A")}
      />
      <InputLevelBar
        val={elecA}
        maxInput={toInt(handConfig.params["elec_a_maximum_input"].value)}
        className="bg-purple-500 rounded-l-md opacity-70"
      />
      <InputLevelBar
        val={elecB}
        maxInput={toInt(handConfig.params["elec_b_maximum_input"].value)}
        className="bg-blue-500 rounded-l-md opacity-70"
      />
      <ThresholdBar
        onVal={onB}
        coconVal={coconActive && coconB}
        maxVal={maxB}
        maxInput={toInt(handConfig.params["elec_b_maximum_input"].value)}
        disabled={disableElectrodeHandles}
        onMove={(...args) => onMove(...args, "B")}
        onRelease={(...args) => onRelease(...args, "B")}
      />
      <div className="h-[12px]"></div>
      <CVSubtitle text={`${ls.getText("electrode_name")} B`} />
    </div>
  )
}
export default ElectrodeData
