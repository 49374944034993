import { useState, useEffect } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { TextField, Autocomplete } from "@mui/material"
import { useAuth } from "@context/AuthContext"
import { getListOfHands } from "@util/firebase/handFunctions"

import { ButtonObj } from "@typesFolder/types"
import BasicModal from "@ui/modals/BasicModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import Spinner from "@ui/spinners/Spinner"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { validateSerialNumber } from "@util/commonFunctions/validateSerialNumber"

interface Props {
  open: boolean
  setOpen: Function
  addAssociatedHand: Function
  loading: boolean
}

const AddAssociatedHandModal = ({ open, setOpen, addAssociatedHand, loading }: Props) => {
  const { profile } = useAuth()
  const [hands, setHands] = useState<string[]>()
  const [serialNumber, setSerialNumber] = useState<string>("")
  const [handNameError, setHandNameError] = useState<string | undefined>()

  useEffect(() => {
    if (profile) {
      getListOfHands(profile).then((hands) => {
        setHands(hands)
      })
    }
  }, [profile])

  useEffect(() => {
    if (serialNumber !== undefined) {
      setHandNameError(validateSerialNumber(serialNumber))
    }
  }, [serialNumber])

  const buttonArray: ButtonObj[] = [
    {
      title: ls.getText("Add Associated Hand"),
      variant: "contained",
      loading: loading,
      disabled: handNameError !== undefined,
      onClick: () => {
        addAssociatedHand(serialNumber)
      },
    },
    {
      title: ls.getText("Cancel"),
      variant: "outlined",
      onClick: () => {
        setOpen(false)
      },
    },
  ]

  return (
    <>
      <BasicModal open={open} setClosed={() => setOpen(false)}>
        <CVCardHeader>
          <CVCardTitle title={ls.getText("Add Associated Hand")} />
        </CVCardHeader>
        <CVCardBody>
          <div className="p-10">
            {hands ? (
              <div className="basis-full">
                <Autocomplete
                  disablePortal
                  sx={{ display: "flex", flexGrow: 1 }}
                  options={hands}
                  onChange={(e, newValue) => {
                    setSerialNumber(newValue ? newValue : "")
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={ls.getText("Serial Number")}
                      onChange={(e) => {
                        setSerialNumber(e.target.value)
                      }}
                      error={!!handNameError}
                      helperText={handNameError ? handNameError : ""}
                    />
                  )}
                  clearOnBlur={false}
                  blurOnSelect
                  value={serialNumber}
                />
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </CVCardBody>
        <CVCardFooter>
          <ButtonRow buttonArray={buttonArray} />
        </CVCardFooter>
      </BasicModal>
    </>
  )
}

export default AddAssociatedHandModal
