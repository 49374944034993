import { PossibleLangLong, PossibleLangShort } from "@typesFolder/types"

export const languagesLong: PossibleLangLong[] = [
  "English",
  "Chinese",
  "Japanese",
  "Spanish",
  "Turkish",
  "Arabic",
]
export const languagesShort: PossibleLangShort[] = ["en", "zh", "ja", "es", "tr", "ar"]

export const languageToShort = (language: PossibleLangLong): PossibleLangShort => {
  switch (language) {
    case "Chinese":
      return "zh"
    case "Japanese":
      return "ja"
    case "Spanish":
      return "es"
    case "Turkish":
      return "tr"
    case "Arabic":
      return "ar"
    default:
      return "en"
  }
}

export const languageShortToLong = (language: PossibleLangShort): PossibleLangLong => {
  switch (language) {
    case "zh":
      return "Chinese"
    case "ja":
      return "Japanese"
    case "es":
      return "Spanish"
    case "tr":
      return "Turkish"
    case "ar":
      return "Arabic"
    default:
      return "English"
  }
}
