import React from "react"

interface Props {
  children: React.ReactNode
  roundedTop?: boolean
  roundedBottom?: boolean
}

const CVCardBody = ({ children, roundedTop, roundedBottom = false }: Props) => {
  return (
    <div
      className={`bg-white w-full flex flex-1 flex-col m-auto overflow-auto 
      ${roundedTop ? "rounded-t-xl" : ""} 
      ${roundedBottom ? "rounded-b-xl drop-shadow-md" : ""}
      `}
    >
      {children}
    </div>
  )
}

export default CVCardBody
