import { languageSelector as ls } from "@covvi/language-selector"
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useCurrentHandContext, useCurrentSessionContext } from "@context/remoteAssistProviders"
import Alert from "@mui/material/Alert"
import BasicModal from "@ui/modals/BasicModal"

const CustomCommand = ({ sendCommand }: { sendCommand: (command: string) => void }) => {
  const [value, setValue] = useState<string>("")
  const [responseDisplay, setResponseDisplay] = useState<String | undefined>()
  const { commandResponse, clearCurrentCommandResponse } = useCurrentHandContext()
  const { setIsInCustomBLECommand } = useCurrentSessionContext()

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendCommand(value)
    setValue("")
  }

  useEffect(() => {
    return () => setIsInCustomBLECommand(false)
  }, [])

  useEffect(() => {
    if (commandResponse !== undefined) {
      if (commandResponse === "5878" || commandResponse === "78") {
        setResponseDisplay(ls.getText("Error Sending Your Request"))
      } else {
        setResponseDisplay(`Success - ${commandResponse}`)
      }
    }
  }, [commandResponse])

  return (
    <form onSubmit={(e) => submitForm(e)}>
      <div className="m-6">
        <TextField
          fullWidth
          className=""
          variant="outlined"
          label={ls.getText("Custom Bluetooth Command")}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          placeholder={ls.getText("Type Your Command")}
        ></TextField>
      </div>
      {responseDisplay && (
        <BasicModal
          open={!!responseDisplay ? true : false}
          setClosed={() => setResponseDisplay(undefined)}
        >
          <Alert
            severity={
              responseDisplay === ls.getText("Error Sending Your Request") ? "error" : "success"
            }
            onClose={() => {
              clearCurrentCommandResponse()
              setResponseDisplay(undefined)
            }}
          >
            {responseDisplay}
          </Alert>
        </BasicModal>
      )}
    </form>
  )
}
export default CustomCommand
