import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { SupportMessage, TicketWithStatus } from "@typesFolder/supportTypes"
import {
  closeReopenTicketMail,
  getSupportMessages,
  getTicketDetails,
  sendSupportEmail,
} from "@util/firebase/supportFunctions"

import TicketInfo from "@components/cards/TicketInfo"

import Spinner from "@ui/spinners/Spinner"
import CVCardBody from "@ui/sections/CVCardBody"
import Correspondence from "@components/cards/Correspondence"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVChatInput from "@ui/inputs/CVChatInput"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { PillButtonArray } from "@ui/sections/PillButtonArray"

const ViewTicket = () => {
  const { profile } = useAuth()
  const location = useLocation()
  const ticketId = location.pathname.split("/")[2]

  const [ticket, setTicket] = useState<TicketWithStatus>()
  const [messages, setmessages] = useState<SupportMessage[]>()
  const [sentMail, setSentMail] = useState(false)

  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const [tabName, setTabName] = useState<"Ticket Info" | "Correspondence">("Ticket Info")

  useEffect(() => {
    setIsLoading(true)
    profile &&
      getTicketDetails(location.pathname.split("/")[2], profile.role)
        .then((ticket) => {
          setIsLoading(false)
          setTicket(ticket)
        })
        .catch((error) => {
          setIsLoading(false)
          setError(error)
        })
  }, [location.pathname, profile])

  const statusHandler = () => {
    let updatedStatus: "open" | "closed"
    let mailStatus: string
    if (ticket?.ticketStatus === "open") {
      updatedStatus = "closed"
      mailStatus = "closed"
    } else {
      updatedStatus = "open"
      mailStatus = "reopened"
    }
    setIsLoading(true)
    closeReopenTicketMail({
      ticketId: ticketId,
      ticketStatus: updatedStatus,
      mailStatus: mailStatus,
      role: profile!.role,
    })
      .then(() => {
        setIsLoading(false)
        ticket && setTicket({ ...ticket, ticketStatus: updatedStatus })
      })
      .catch((error) => {
        setIsLoading(false)
        profile?.role === "Admin" && console.log(error)
      })
  }

  useEffect(() => {
    getSupportMessages(ticketId, profile!.role)
      .then((messages) => {
        const filtered = messages.sort((a: { date: string }, b: { date: string }) => {
          return sort(a, b)
        })
        setmessages(filtered)
      })
      .catch((error) => {
        console.error(error.message)
      })
  }, [sentMail, ticketId, profile])

  const sort = (a: { date: string }, b: { date: string }) => {
    if (a.date < b.date) {
      return -1
    } else if (a.date > b.date) {
      return 1
    } else {
      return 0
    }
  }

  const sendMail = (reply: string) => {
    setSentMail(false)
    setmessages([])
    sendSupportEmail({
      from_email: profile!.email_address,
      name: "",
      ticketId: ticketId,
      email_content: reply.split(/\r?\n/),
      attachments: [],
      role: profile!.role,
    })
      .then(() => {
        setSentMail(true)
      })
      .catch((error: string) => {
        console.error(error)
      })
  }

  const pillArray = [
    { title: "Ticket Info", onClick: () => setTabName("Ticket Info") },
    { title: "Correspondence", onClick: () => setTabName("Correspondence") },
  ]

  return ticket ? (
    <>
      <PillButtonArray
        pillArray={pillArray}
        selected={tabName}
        setSelected={setTabName}
        extraMarginBottom={true}
      />
      <CVCardBody roundedTop={true}>
        {tabName === "Ticket Info" && <TicketInfo ticketInfo={ticket} />}
        {tabName === "Correspondence" && <Correspondence messages={messages} />}
      </CVCardBody>
      <CVCardFooter>
        {tabName === "Ticket Info" && (
          <ButtonRow
            buttonArray={[
              {
                loading: isLoading,
                onClick: statusHandler,
                title:
                  ticket.ticketStatus === "open"
                    ? ls.getText("Close Ticket")
                    : ls.getText("Re-open Ticket"),
                variant: "contained",
              },
            ]}
          />
        )}
        {tabName === "Correspondence" && <CVChatInput isMultiline={true} send={sendMail} />}
      </CVCardFooter>
    </>
  ) : (
    <Spinner />
  )
}

export default ViewTicket
